import {Stack} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type * as React from 'react';
import {createContext, useCallback, useEffect, useState} from 'react';
import SignIn from '../Pages/SignIn';

type Props = {
    children?: React.ReactNode;
};

export type JWTUser = {
    accessToken: string;
};

export const JwtContext = createContext<JWTUser | null>(null);

const JWTProvider: React.FC<Props> = ({children}: Props): React.ReactElement => {
    const [user, setUser] = useState<JWTUser | null>();

    useEffect(() => {
        const user = localStorage.getItem('JWTUser');

        if (!user) {
            setUser(null);
            return;
        }

        const localUser: JWTUser = JSON.parse(user);

        setUser(localUser);
    }, []);

    if (user === undefined) {
        return (
            <Stack
                sx={{
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress sx={{mt: 1}} />
            </Stack>
        );
    }

    if (user === null) {
        return <SignIn />;
    }

    return <JwtContext.Provider value={user}>{children}</JwtContext.Provider>;
};

type AuthenticateUser = (studentId: number, birthDate: string) => Promise<JWTUser | null>;

export const useAuthenticateUser = (): AuthenticateUser => {
    return useCallback(async (username: number, password: string): Promise<JWTUser | null> => {
        const init: RequestInit = {
            headers: new Headers({'Content-Type': 'application/json'}),
            method: 'POST',
        };
        init.body = JSON.stringify({
            username: username,
            password: password,
        });
        const response = await fetch(
            `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/login/authenticate`,
            init,
        );

        if (response.status !== 200) {
            return null;
        }

        const data = (await response.json()) as {jwt: string};
        const user = {
            accessToken: data.jwt,
        };

        localStorage.setItem('JWTUser', JSON.stringify(user));

        return user;
    }, []);
};

export const signOut = (): void => {
    localStorage.removeItem('JWTUser');
};

export default JWTProvider;
