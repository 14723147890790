import type * as React from "react";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const ScrollToTopComponent: React.FC = (): React.ReactElement => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return <></>;
}

export default ScrollToTopComponent;
