import type React from 'react';
import {useEffect, useState} from 'react';
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import availableLanguages from "@/i18n/generatedTranslations/availableLanguage.json";
import type {DashboardParams} from "@/components/Pages/EnrollmentDashboard.tsx";
import Menu from "@mui/material/Menu";

type Props = {
    handleCloseHamburgerMenu : () => void
};

const SelectLanguageMenuItem = ({handleCloseHamburgerMenu} : Props) : React.ReactElement => {
    const {t, i18n} = useTranslation();
    const [languagesMenuElement, setLanguagesMenuElement] = useState<HTMLElement | null>(null);
    const {languageCode} = useParams<DashboardParams>();
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('i18nextLng') ?? 'en',
    );

    const handleShowLanguages = (event: React.MouseEvent<HTMLElement>) => {
        setLanguagesMenuElement(event.currentTarget);
    };

    const handleSelectLanguage = (value: string) => {
        setLanguagesMenuElement(null);
        setSelectedLanguage(value);
        handleCloseHamburgerMenu();
    };

    const handleClose = () => {
        setLanguagesMenuElement(null);
        handleCloseHamburgerMenu();
    };

    useEffect(() => {
        if (selectedLanguage !== i18n.language) {
            void i18n.changeLanguage(selectedLanguage);
            setSelectedLanguage(selectedLanguage);
        }
    }, [i18n, selectedLanguage]);

    useEffect(() => {
        if (languageCode && languageCode !== selectedLanguage) {
            setSelectedLanguage(languageCode);
        }
    }, [languageCode, selectedLanguage]);

    return <MenuItem onClick={handleShowLanguages}>
        {t('languageChoice')}
        <Menu
            id="language-menu"
            anchorEl={languagesMenuElement}
            keepMounted
            open={Boolean(languagesMenuElement)}
            onClose={handleClose}
        >
            <MenuItem
                value='en'
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    handleSelectLanguage('en');
                }}
            >
                English
            </MenuItem>
            {availableLanguages.map(
                (language: {code: string; name: string}) => {
                    return (
                        <MenuItem
                            key={`language-option-${language.code}`}
                            value={language.code}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();
                                handleSelectLanguage(language.code);
                            }}
                        >
                            {language.name}
                        </MenuItem>
                    );
                },
            )}
        </Menu>
    </MenuItem>
}

export default SelectLanguageMenuItem;
