import LoginIcon from '@mui/icons-material/Login';
import StandardField from '@/components/Forms/Fields/StandardField.tsx';
import GreyButton from '@/components/Forms/GreyButton.tsx';
import {Alert, Box, Button, Divider, Stack} from '@mui/material';
import type {FormikValues} from 'formik';
import {Form, Formik} from 'formik';
import type * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {isState, phoneValidation, postalCodeUS, states} from '../Helpers/validationHelpers';
import PhoneField from './Fields/PhoneField';

type Props = {
    handleCreateEnrollmentFormSubmit: (values: FormikValues) => void;
    handleBack: () => void;
    hasMessage: boolean;
    setHasMessage: (hasMessage: boolean) => void;
    hasError: boolean;
    message: string;
};

const CreateEnrollmentForm = ({
    handleCreateEnrollmentFormSubmit,
    handleBack,
    hasMessage,
    hasError,
    setHasMessage,
    message,
}: Props): React.ReactElement => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

    const initialValues = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
    };

    const CreateEnrollmentSchema = yup.object({
        email: yup
            .string()
            .email()
            .required(`${t('sefEmail')} ${t('requiredError')}`),
        firstName: yup.string().required(`${t('sefFirstName')} ${t('requiredError')}`),
        lastName: yup.string().required(`${t('sefLastName')} ${t('requiredError')}`),
        phone: phoneValidation(`${t('sefPhone')} ${t('requiredError')}`).required(
            `${t('sefPhone')} ${t('requiredError')}`,
        ),
        address: yup.string().required(`${t('sefAddress')} ${t('requiredError')}`),
        city: yup.string().required(`${t('sefCity')} ${t('requiredError')}`),
        state: isState.required(`${t('sefState')} ${t('requiredError')}`),
        zip: postalCodeUS(`${t('sefZipCode')} ${t('requiredError')}`).required(
            `${t('sefZipCode')} ${t('requiredError')}`,
        ),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CreateEnrollmentSchema}
            onSubmit={values => {
                setLoading(true);
                handleCreateEnrollmentFormSubmit(values);
                setFormIsModified(false);
                setLoading(false);
            }}
            enableReinitialize={true}
            validateOnChange={formHasBeenSubmitted}
            validateOnBlur={formHasBeenSubmitted}
        >
            {props => {
                const isValid = props.isValid;
                return (
                    <Form style={{marginTop: '24px'}}>
                        <Stack sx={{p: 3, pb: 1}}>
                            <Box sx={{textAlign: 'center'}}>
                                {t('beforeCreate1')} <b>{t('beforeCreate2')}</b> {t('button')}.
                            </Box>
                            <Box sx={{mt: 3, textAlign: 'center'}}>{t('beforeNote')}</Box>
                            <Stack sx={{mt: 3, alignItems: 'center'}}>
                                <Box sx={{width: '100%', maxWidth: '600px'}}>
                                    <StandardField
                                        name='email'
                                        type='text'
                                        label={`${t('sefEmail')}:`}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                    />
                                    <StandardField
                                        name='firstName'
                                        type='text'
                                        label={`${t('sefFirstName')}:`}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                        stackSx={{
                                            mt: 2,
                                        }}
                                    />
                                    <StandardField
                                        name='lastName'
                                        type='text'
                                        label={`${t('sefLastName')}:`}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                        stackSx={{
                                            mt: 2,
                                        }}
                                    />
                                    <PhoneField
                                        name='phone'
                                        label={`${t('sefPhone')}:`}
                                        stackSx={{
                                            mt: 2,
                                        }}
                                    />
                                    <StandardField
                                        name='address'
                                        type='text'
                                        label={`${t('sefAddress')}:`}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                        stackSx={{
                                            mt: 2,
                                        }}
                                    />
                                </Box>
                                <Stack
                                    sx={{
                                        width: '100%',
                                        maxWidth: '600px',
                                        flexDirection: {xs: 'column', md: 'row'},
                                    }}
                                >
                                    <StandardField
                                        name='city'
                                        type='text'
                                        label={`${t('sefCity')}:`}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                        stackSx={{
                                            width: '100%',
                                            mt: 2,
                                        }}
                                    />
                                    <StandardField
                                        name='state'
                                        type='select'
                                        label={t('sefState')}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                        options={states.map(state => {
                                            return {
                                                checked: false,
                                                label: state,
                                                value: state,
                                            };
                                        })}
                                        stackSx={{
                                            mt: 2,
                                            mx: {xs: 0, md: 2},
                                            minWidth: '100px',
                                        }}
                                    />
                                    <StandardField
                                        name='zip'
                                        type='text'
                                        label={`${t('sefZipCode')}:`}
                                        onChange={() => {
                                            if (hasMessage) {
                                                setHasMessage(false);
                                            }
                                        }}
                                        stackSx={{
                                            mt: 2,
                                        }}
                                    />
                                </Stack>
                                <Divider sx={{py: 2, width: '100%', borderColor: '#000'}} />
                            </Stack>
                        </Stack>
                        {hasError && (
                            <Alert
                                key={'global-error'}
                                icon={false}
                                variant={'standard'}
                                color={'error'}
                                sx={{mx: 3, mb: 1}}
                            >
                                {message}
                            </Alert>
                        )}
                        {hasMessage && (
                            <Alert
                                key={'global-error'}
                                icon={false}
                                variant={'standard'}
                                color={'warning'}
                                sx={{mx: 3, mb: 1}}
                            >
                                {message}
                            </Alert>
                        )}
                        <Stack
                            sx={{
                                px: 3,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                mb: 3,
                            }}
                        >
                            <GreyButton callback={handleBack} />
                            <Button
                                color={
                                    formIsModified
                                        ? !isValid
                                            ? 'error'
                                            : 'secondary'
                                        : !isValid
                                          ? 'error'
                                          : 'secondary'
                                }
                                variant={formIsModified && !isValid ? 'contained' : 'outlined'}
                                disabled={loading || !isValid}
                                onClick={async () => {
                                    setFormHasBeenSubmitted(true);
                                    await props.submitForm();
                                }}
                            >
                                {t('login')} &nbsp;
                                <LoginIcon />
                            </Button>
                        </Stack>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateEnrollmentForm;
