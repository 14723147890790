import {ThemeProvider, createTheme} from '@mui/material';
import {StrictMode} from 'react';
import 'react-datetime/css/react-datetime.css';
import {createRoot} from 'react-dom/client';
import App from './App';
import './i18n/i18nConfig';
import './index.scss';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0d6efd',
            // dark: '#000',
            // contrastText: '#fff',
        },
        secondary: {
            main: '#000',
            dark: '#000',
            contrastText: '#fff',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    fontSize: '12px',
                    '&:hover': {
                        backgroundColor: '#000',
                        color: '#fff',
                    },
                    minWidth: '140px',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
});

const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const root = createRoot(container);

root.render(
    <StrictMode>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </StrictMode>,
);
