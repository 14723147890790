import Logo from '@/components/Branding/Logo';
import {Box, Stack} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import type React from 'react';
import HamburgerMenu from "@/components/Navigation/HamburgerMenu";
import SignOutButton from "@/components/Navigation/SignOutButton";
import ShowDashboardButton from "@/components/Navigation/ShowDashboardButton";
import SelectLanguageMenu from "@/components/Navigation/SelectLanguageMenu";

type Props = {
    showSignOut: boolean;
    showDashboard?: boolean;
};

const Navbar = ({showSignOut, showDashboard}: Props): React.ReactElement => {
    return (
        <AppBar
            sx={{
                backgroundColor: '#fff',
                height: '117px',
            }}
        >
            <Container>
                <Stack
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Box sx={{pl: 6, pt: 2}}>
                        <Logo />
                    </Box>

                    <Stack
                        sx={{
                            flexDirection: 'row',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                        }}
                    >

                        <SelectLanguageMenu sx={{display: {xs: 'none', md: 'flex'}}}/>
                        {showDashboard && <ShowDashboardButton sx={{display: {xs: 'none', md: 'flex'}}}/>}
                        {showSignOut && <SignOutButton sx={{display: {xs: 'none', md: 'flex'}}}/>}
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                            display: {xs: 'flex', md: 'none'},
                        }}
                    >
                        <Stack
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                color: '#000',
                            }}
                        >
                            <HamburgerMenu
                                showDashboard={showDashboard}
                                showSignOut={showSignOut}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </AppBar>
    );
};

export default Navbar;
