
const formatDateOrString = (date: Date | string | undefined) => {
    if (typeof date === 'string') {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).format(new Date(date));
    }

    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(date ?? new Date());
};

export default formatDateOrString;
