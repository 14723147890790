import LoginIcon from '@mui/icons-material/Login';
import {Alert, Box, Button, Divider, Stack, TextField} from '@mui/material';
import type * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {doResendEnrollmentEmail} from '../../hooks/enrollment';

type Props = {
    showButton?: boolean;
    handleBack: () => void;
};

const ContinueEnrollmentForm: React.FC<Props> = ({
    showButton,
    handleBack,
}: Props): React.ReactElement => {
    const {t, i18n} = useTranslation();
    const [hasError, setHasError] = useState<boolean>(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const {mutate: resendEnrollmentEmail} = useMutation(doResendEnrollmentEmail, {
        onSuccess: () => {
            setEmailSent(true);
        },
        onError: () => {
            setHasError(true);
        },
    });

    const handleResendEmail = () => {
        setHasError(false);
        resendEnrollmentEmail({
            email: emailAddress,
            languageCode: i18n.language,
        });
    };

    return (
        <Stack sx={{flexDirection: 'column'}}>
            {showButton && (
                <Box style={{display: 'block'}}>
                    <Button
                        color='secondary'
                        variant='outlined'
                        sx={{
                            minWidth: '30px',
                            minHeight: '30px',
                            backgroundColor: 'grey',
                        }}
                    >
                        {t('continueEnrollment')}
                    </Button>
                </Box>
            )}
            {hasError && (
                <Alert color={'error'} sx={{m: 2}}>
                    {t('error')}: {emailAddress} {t('errorNotFound1')} <b>{t('errorNotFound2')}</b>{' '}
                    {t('errorNotFound3')}
                </Alert>
            )}
            {emailSent && (
                <Stack sx={{p: 3, alignItems: 'center'}}>
                    <Box sx={{mt: 3}}>{t('enrollmentLocated') + emailAddress}</Box>
                    <Box sx={{mt: 3, ml: 1}}>
                        {t('fiveMin')}
                        <a href='mailto: helpdesk@glenbrook225.org'>helpdesk@glenbrook225.org</a>.
                    </Box>
                    <Box sx={{mt: 3}}>{t('canClose')}</Box>
                </Stack>
            )}
            {!emailSent && (
                <form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <Box sx={{mt: 3, textAlign: 'center'}}>
                        {t('resumeApplication1')} <b>{t('continue2')}</b> {t('button')}.{' '}
                        {t('resumeApplication2')}
                    </Box>
                    <Stack sx={{mt: 3, alignItems: 'center'}}>
                        <Box sx={{width: '100%', maxWidth: '400px'}}>
                            <TextField
                                fullWidth
                                placeholder={t('sef203GuardianEmail')}
                                aria-label={t('sef203GuardianEmail')}
                                aria-describedby='email-address'
                                value={emailAddress}
                                onChange={e => {
                                    setEmailAddress(e.currentTarget.value);
                                }}
                                inputProps={{
                                    style: {
                                        padding: 5,
                                        fontSize: '14px',
                                    },
                                }}
                            />
                        </Box>
                    </Stack>
                    <Divider sx={{py: 2, width: '100%', borderColor: '#000'}} />
                    <Stack
                        sx={{flexDirection: 'row', justifyContent: 'space-between', mb: 3, mt: 2}}
                    >
                        <Button
                            color='secondary'
                            variant='outlined'
                            onClick={() => {
                                handleBack();
                            }}
                        >
                            {t('back')} &nbsp;
                        </Button>
                        <Button
                            color='secondary'
                            variant='outlined'
                            onClick={() => {
                                handleResendEmail();
                            }}
                        >
                            {t('continueEnrollment')} &nbsp;
                            <LoginIcon/>
                        </Button>
                    </Stack>
                </form>
            )}
        </Stack>
    );
};

export default ContinueEnrollmentForm;
