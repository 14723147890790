import FormLabel from '@/components/Forms/Fields/FormLabel.tsx';
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    type SxProps,
    TextField
} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {type FormikValues, useFormikContext} from 'formik';
import type {FormikContextType} from 'formik/dist/types';
import moment from 'moment';
import type {ChangeEvent} from 'react';
import * as React from 'react';
import Datetime from 'react-datetime';
import {FormControlContext} from '../../Providers/FormControlContext';

export type StandardFieldOption = {
    label: string;
    value: string;
    checked: boolean;
};

type Props = {
    name: string;
    type: string;
    label: string;
    options?: StandardFieldOption[];
    disabled?: boolean;
    handleSetFormIsModified?: (formIsModified: boolean) => void;
    arrayName?: string;
    iteration?: number;
    className?: string;
    onChange?: (e: ChangeEvent<any> | string) => void;
    fieldLabelClass?: string;
    hideCheckboxDescription?: boolean;
    errorNoticeOverride?: string;
    placeholder?: string;
    readOnly?: boolean;
    stackSx?: SxProps;
};

const StandardField = ({
    name,
    type,
    label,
    options,
    disabled,
    arrayName,
    iteration,
    className,
    onChange,
    fieldLabelClass,
    hideCheckboxDescription,
    errorNoticeOverride,
    placeholder,
    readOnly,
    stackSx,
}: Props) => {
    const {values, handleChange, errors, setFieldValue}: FormikContextType<FormikValues> =
        useFormikContext();
    const {setFieldModified} = React.useContext(FormControlContext);
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const fieldValue = arrayName && iteration !== undefined ? values[arrayName][iteration][name] : values[name];

    let errorsName: string | undefined;

    if (errors[fieldName]) {
        errorsName = errors[fieldName] as string
    }

    if (arrayName !== undefined && iteration !== undefined) {
        const errorsArr = errors[arrayName] as Record<string, string>[] | undefined;

        if (errorsArr) {
            for (let i = 0; i < errorsArr.length; i++) {
                if (errorsArr[i] === undefined) {
                    continue;
                }

                if (i === iteration && errorsArr[i] !== undefined && errorsArr[i][name] !== null) {
                    errorsName = errorsArr[iteration][name];
                }
            }
        }
    }

    if (type === 'text' || type === 'time' || type === 'password') {
        return (
            <Stack sx={stackSx}>
                {label && <FormLabel>{label}&nbsp;</FormLabel>}
                <TextField
                    fullWidth
                    type={type}
                    name={fieldName}
                    value={fieldValue === null ? '' : fieldValue}
                    onChange={async e => {
                        handleChange(e);

                        if (setFieldModified) {
                            setFieldModified(fieldName);
                        }

                        onChange?.(e);
                    }}
                    // isInvalid={!!errorsName || !!errorNoticeOverride}
                    error={!!errorsName || !!errorNoticeOverride}
                    disabled={disabled}
                    className={className ? className : ''}
                    placeholder={placeholder}
                    inputProps={{
                        readOnly: readOnly,
                        style: {
                            padding: 5,
                            fontSize: '14px',
                        },
                    }}
                />
                <Typography
                    sx={{color: 'rgb(220, 53, 69)', mt: 1, mb: 2, fontSize: '14px', fontWeight: 400}}
                >
                    {errorsName ? errorsName : errorNoticeOverride ? errorNoticeOverride : ''}
                </Typography>
            </Stack>
        );
    }

    if (type === 'date') {
        return (
            <Stack sx={{width: '100%'}}>
                {label && <FormLabel>{label}&nbsp;</FormLabel>}
                <Datetime
                    onChange={async date => {
                        await setFieldValue(
                            fieldName,
                            moment.isMoment(date) ? date.format('MM/DD/YYYY') : date,
                        );

                        if (setFieldModified) {
                            setFieldModified(fieldName);
                        }

                        onChange?.(moment.isMoment(date) ? date.format('MM/DD/YYYY') : date);
                    }}
                    className={`${!!errorsName || !!errorNoticeOverride ? 'date-input-error' : ''}`}
                    initialValue={
                        fieldValue !== '' && fieldValue !== '?'
                            ? moment(fieldValue).format('MM/DD/YYYY')
                            : ''
                    }
                    timeFormat={false}
                    input={true}
                    dateFormat='MM/DD/YYYY'
                    inputProps={{
                        placeholder: 'MM/DD/YYYY',
                        disabled: disabled,
                        className:
                            !!errorsName || !!errorNoticeOverride
                                ? 'form-control is-invalid'
                                : 'form-control',
                        pattern: '\\d{2}/\\d{2}/\\d{4}',
                        style: {
                            width: '100%',
                            borderRadius: '2px',
                        },
                    }}
                    closeOnSelect={true}
                    renderInput={props => {
                        return (
                            <TextField
                                {...props}
                                value={fieldValue ? props.value : ''}
                                inputProps={{
                                    style: {
                                        padding: 5,
                                        fontSize: '14px',
                                        width: '100%',
                                    },
                                }}
                            />
                        );
                    }}
                />
                {!!errorsName || !!errorNoticeOverride ? (
                    <Typography
                        sx={{color: 'rgb(220, 53, 69)', mt: 1, mb: 2, fontSize: '14px', fontWeight: 400}}
                    >
                        {errorsName ? errorsName : errorNoticeOverride ? errorNoticeOverride : ''}
                    </Typography>
                ) : (
                    <></>
                )}
            </Stack>
        );
    }

    if (type === 'textarea') {
        return <Stack>
            {label && (
                <FormLabel>{label}</FormLabel>
            )}
            <TextField
                name={fieldName}
                value={fieldValue}
                onChange={async (e) => {
                    handleChange(e);
                    if (setFieldModified) {
                        setFieldModified(fieldName);
                    }

                    onChange?.(e);
                }}
                minRows={5}
                disabled={disabled}
                InputProps={{
                    inputComponent: TextareaAutosize,
                }}
            />
            <Typography
                sx={{color: 'rgb(220, 53, 69)', mt: 1, mb: 2, fontSize: '14px', fontWeight: 400}}
            >
                {errorsName ? errorsName : errorNoticeOverride ? errorNoticeOverride : ''}
            </Typography>
        </Stack>
    }

    if (type === 'checkbox') {
        return (
            <Stack>
                <Box className={fieldLabelClass}>
                    {label && <FormLabel>{label}</FormLabel>}
                    {(!!errorsName || !!errorNoticeOverride) && (
                        <Typography
                            sx={{
                                color: 'rgb(220, 53, 69)',
                                mt: 1,
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >
                            {errorsName
                                ? errorsName
                                : errorNoticeOverride
                                  ? errorNoticeOverride
                                  : ''}
                        </Typography>
                    )}
                </Box>
                <FormGroup>
                    {options?.map((option: StandardFieldOption, index) => {
                        if (fieldValue && Array.isArray(fieldValue) && fieldValue.includes(null)) {
                            const index = fieldValue.indexOf(null);
                            fieldValue.splice(index);
                        }

                        if (fieldValue && Array.isArray(fieldValue) && fieldValue.includes(null)) {
                            const index = fieldValue.indexOf('');
                            fieldValue.splice(index);
                        }

                        let defaultChecked = false;

                        if (
                            fieldValue &&
                            Array.isArray(fieldValue) &&
                            fieldValue.includes(option.value)
                        ) {
                            defaultChecked = true;
                        }

                        if (!Array.isArray(fieldValue)) {
                            defaultChecked = fieldValue.toString() === option.value;
                        }

                        return (
                            <Box key={option.label}>
                                <FormControlLabel
                                    label={
                                        !hideCheckboxDescription ? (
                                            <Typography sx={{fontSize: '14px'}}>
                                                {option.label}
                                            </Typography>
                                        ) : false
                                    }
                                    control={
                                        <Checkbox
                                            name={fieldName}
                                            value={option.value}
                                            onChange={async (
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                const value = e.target.checked
                                                    ? option.value
                                                    : null;
                                                let newFieldValues = fieldValue;

                                                if (Array.isArray(newFieldValues)) {
                                                    if (
                                                        value === null &&
                                                        newFieldValues !== null
                                                    ) {
                                                        newFieldValues = newFieldValues.filter(
                                                            (newValue: string) =>
                                                                newValue !== option.value,
                                                        );
                                                    } else {
                                                        if (newFieldValues === null) {
                                                            newFieldValues = [];
                                                        }

                                                        newFieldValues.push(value);
                                                    }

                                                    setFieldValue(fieldName, newFieldValues);
                                                } else {
                                                    setFieldValue(
                                                        `${fieldName}.${index}`,
                                                        value,
                                                    );
                                                }

                                                if (setFieldModified) {
                                                    setFieldModified(fieldName);
                                                }

                                                onChange?.(e);
                                            }}
                                            checked={defaultChecked}
                                            // isInvalid={!!errorsName || !!errorNoticeOverride}
                                            id={`${fieldName}-${index}`}
                                            disabled={disabled}
                                        />
                                    }
                                />
                            </Box>
                        );
                    })}
                </FormGroup>
            </Stack>
        );
    }

    if (type === 'radio') {
        return (
            <Stack>
                <Box className={fieldLabelClass}>
                    {label && <FormLabel>{label}</FormLabel>}
                    {(!!errorsName || !!errorNoticeOverride) && (
                        <Typography
                            sx={{
                                color: 'rgb(220, 53, 69)',
                                mt: 1,
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >
                            {errorsName
                                ? errorsName
                                : errorNoticeOverride
                                  ? errorNoticeOverride
                                  : ''}
                        </Typography>
                    )}
                </Box>
                <RadioGroup
                    name={fieldName}
                    value={fieldValue}
                    sx={{...stackSx}}
                    onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        const value = (e.target as HTMLInputElement).value;
                        await setFieldValue(`${fieldName}`, value);

                        if (setFieldModified) {
                            setFieldModified(fieldName);
                        }

                        onChange?.(value ? value.toString() : '');
                    }}
                >
                    {options?.map((option: StandardFieldOption) => {
                        if (fieldValue && Array.isArray(fieldValue) && fieldValue.includes(null)) {
                            const index = fieldValue.indexOf(null);
                            fieldValue.splice(index);
                        }

                        if (fieldValue && Array.isArray(fieldValue) && fieldValue.includes(null)) {
                            const index = fieldValue.indexOf('');
                            fieldValue.splice(index);
                        }

                        // let defaultChecked = false;
                        //
                        // if (
                        //     fieldValue &&
                        //     Array.isArray(fieldValue) &&
                        //     fieldValue.includes(option.value)
                        // ) {
                        //     defaultChecked = true;
                        // }
                        //
                        // if (!Array.isArray(fieldValue)) {
                        //     defaultChecked = fieldValue.toString() === option.value;
                        // }

                        return <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                        />;
                    })}
                </RadioGroup>
            </Stack>
        );
    }

    if (type === 'select') {
        return (
            <Stack sx={{width: '100%', ...stackSx}}>
                {label && <FormLabel>{label}</FormLabel>}
                <FormControl margin='none' size='small' fullWidth>
                    <InputLabel
                        id={`field-${fieldName}`}
                        color='primary'
                        sx={{
                            mt: -0.5,
                            color: '#999',
                            fontSize: '14px',
                            '&.MuiInputLabel-shrink':{
                                color:'red',
                                display: 'none',
                            }
                        }}
                    >
                        {label.replace(':', '')}
                    </InputLabel>
                    <Select
                        labelId={`field-${fieldName}`}
                        type={type}
                        name={fieldName}
                        value={fieldValue ?? ''}
                        onChange={async e => {
                            handleChange(e);

                            if (setFieldModified) {
                                setFieldModified(fieldName);
                            }

                            onChange?.(e.target.value);
                        }}
                        // isInvalid={!!errorsName || !!errorNoticeOverride}
                        error={!!errorsName || !!errorNoticeOverride}
                        disabled={disabled}
                        className={className ? className : ''}
                        // inputProps={{
                        //     readOnly: readOnly,
                        // }}
                        sx={{
                            '& .MuiSelect-outlined': {
                                p: '3.5px',
                                fontSize: '14px',
                                pl: 1.5,
                            },
                        }}
                    >
                        {/*<MenuItem value=''> -- Select --</MenuItem>*/}
                        {options?.map((option: StandardFieldOption) => {
                            return (
                                <MenuItem value={option.value} key={option.label}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Typography
                        sx={{color: 'rgb(220, 53, 69)', mt: 1, mb: 2, fontSize: '14px', fontWeight: 400}}
                    >
                        {errorsName ? errorsName : errorNoticeOverride ? errorNoticeOverride : ''}
                    </Typography>
                </FormControl>
            </Stack>
        );
    }

    if (type === 'hidden') {
        return (
            <TextField
                type={type}
                name={fieldName}
                value={fieldValue === null ? '' : fieldValue}
                sx={{
                    display: 'none',
                }}
            />
        );
    }

    return <Box>Unknown Element ({type})</Box>;
};

export default StandardField;
