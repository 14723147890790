import type {SxProps} from '@mui/material';
import {Stack} from '@mui/material';
import type * as React from 'react';

type Props = {
    children: React.ReactElement | React.ReactElement[];
    sx?: SxProps;
};

const StackColumn = ({children, sx}: Props): React.ReactElement => {
    return (
        <Stack
            sx={{
                flexDirection: 'column',
                ...sx,
            }}
        >
            {children}
        </Stack>
    );
};

export default StackColumn;
