import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import type * as React from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
    show: boolean;
    handleClose: () => void;
    errorMessage: string;
};

const ErrorModal: React.FC<Props> = (
    {
        show,
        handleClose,
        errorMessage,
    }: Props): React.ReactElement => {
    const {t} = useTranslation();

    return (
        <>
            <Dialog open={show} onClose={handleClose}>
                <DialogTitle>{t('error')}</DialogTitle>
                <DialogContent>
                    {t(errorMessage)}
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' variant='outlined' onClick={handleClose}>
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ErrorModal;
