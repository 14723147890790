import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import english from './en/common.json';

const DEFAULT_LANGUAGE = localStorage.getItem('i18nextLng') ?? 'en';

const defaultBundle = async (locale: string, ns: string) => {
    if (locale === 'en') {
        return english;
    }

    const res = await import(`./generatedTranslations/${locale}/${ns}.json`);
    return res.default;
};

const loadLocaleBundle = async (locale: string, ns: string) => {
    try {
        return await defaultBundle(locale, ns);
    } catch (err) {
        console.error(err);
    }
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // resources,
        lng: DEFAULT_LANGUAGE,
        defaultNS: 'common',
        ns: ['common', 'language'],
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '{{lng}}|{{ns}}',
            request: (
                _options: unknown,
                url: {split: (arg0: string) => [string, string]},
                _payload: unknown,
                callback: (arg0: null, arg1: {data?: string; status: number}) => void,
            ) => {
                try {
                    const [lng, ns] = url.split('|');

                    loadLocaleBundle(lng, ns).then(data => {
                        callback(null, {
                            data: JSON.stringify(data),
                            status: 200,
                        });
                    });
                } catch (e) {
                    console.error(e);
                    callback(null, {
                        status: 500,
                    });
                }
            },
        },
        react: {
            // wait: true,
            useSuspense: true,
        },
    });

export default i18n;
