import MenuIcon from '@mui/icons-material/Menu';
import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormControlContext} from "@/components/Providers/FormControlContext.tsx";
import SelectLanguageMenuItem from "@/components/Navigation/SelectLanguageMenuItem.tsx";

type Props = {
    showSignOut: boolean;
    showDashboard?: boolean;
};

const HamburgerMenu = ({showSignOut, showDashboard}: Props): React.ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const {handleFormSave, formRef} = React.useContext(FormControlContext);

    const handleClickHamburger = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        handleCloseMenu();
        navigate('/signout');
    };

    const handleDashboard = () => {
        handleFormSave(formRef.current.values, 'close');
        handleCloseMenu();
    };

    return (
        <>
            <IconButton
                size="small"
                aria-label="open drawer"
                edge="start"
                color="inherit"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClickHamburger}
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <SelectLanguageMenuItem handleCloseHamburgerMenu={handleCloseMenu}/>
                {showDashboard && <MenuItem onClick={handleDashboard}>{t('dashboard')}</MenuItem>}
                {showSignOut && <MenuItem onClick={handleSignOut}>{t('logout')}</MenuItem>}
            </Menu>
        </>
    );
}

export default HamburgerMenu;
