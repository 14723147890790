import Navbar from '@/components/Navigation/Navbar.tsx';
import {Stack} from '@mui/material';
import Container from '@mui/material/Container';
import * as React from 'react';

const NotFound404 = () => {
    return (
        <React.Fragment>
            <Navbar showSignOut={false} />
            <Container sx={{mt: '135px'}}>
                <Stack
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 5,
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        p: 3,
                        minHeight: '300px',
                    }}
                >
                    <h1>Page Not Found</h1>
                    <p>
                        Uh oh, we can't seem to find the page you're looking for. Try going back to
                        the previous page or contact support@aquaticcontrol.com assistance.
                    </p>
                </Stack>
            </Container>
        </React.Fragment>
    );
};

export default NotFound404;
