import React from 'react';
import {Box, Stack, type SxProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {FormControlContext} from "@/components/Providers/FormControlContext.tsx";

type Props = {
    sx ?: SxProps;
};

const ShowDashboardButton = ({sx} : Props) : React.ReactElement => {
    const {t} = useTranslation();
    const {handleFormSave, formRef} = React.useContext(FormControlContext);

    return <Stack
        sx={{
            flexDirection: 'column',
            justifyContent: 'flex-end',
            px: 2,
            ...sx,
        }}
        onClick={() => {
            handleFormSave(formRef.current.values, 'close');
        }}
    >
        <Stack
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
            }}
        >
            <DashboardIcon
                sx={{
                    color: '#737373',
                    mb: 1,
                }}
            />

            <Box sx={{pt: 1, color: '#737373 !important'}}>
                {t('dashboard')}
            </Box>
        </Stack>
    </Stack>
}

export default ShowDashboardButton;
