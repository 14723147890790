import {Container} from '@mui/material';
import type {FormikValues} from 'formik';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DismissibleAlert from '../DismissibleAlert.tsx';
import SignInForm from '../Forms/SignInForm';
import type {JWTUser} from '../Providers/JWTProvider';
import {useAuthenticateUser} from '../Providers/JWTProvider';

export const signInError = 'Error: Invalid Username or Password or bad connection.';

const SignIn = () => {
    const {t} = useTranslation();
    const authenticateUser = useAuthenticateUser();
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const handleSignInSubmit = useCallback(
        async (values: FormikValues) => {
            let user: JWTUser | null = await authenticateUser(values.username, values.password);
            setShowAlert(false);

            if (
                import.meta.env.VITE_REACT_APP_ENABLE_JWT_OVERRIDE &&
                import.meta.env.VITE_REACT_APP_ADMIN_JWT
            ) {
                user = {accessToken: import.meta.env.VITE_REACT_APP_ADMIN_JWT};
                localStorage.setItem('JWTUser', JSON.stringify(user));
            }

            if (user) {
                window.location.href = '/admin-dashboard';
            } else {
                setShowAlert(true);
            }
        },
        [authenticateUser],
    );

    return (
        <>
            <Container
                maxWidth='sm'
                sx={{backgroundColor: '#fff', p: 2, mt: 2, borderRadius: '8px'}}
            >
                <DismissibleAlert
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    message={t('errorLogin')}
                />
                <SignInForm handleSignInSubmit={handleSignInSubmit} />
            </Container>
        </>
    );
};

export default SignIn;
