import LoginIcon from '@mui/icons-material/Login';
import StandardField from '@/components/Forms/Fields/StandardField.tsx';
import {Box, Button, Stack} from '@mui/material';
import type {FormikValues} from 'formik';
import {Form, Formik} from 'formik';
import type * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import * as yup from 'yup';
import {useNotice} from '../Providers/NoticeProvider';

type Props = {
    handleSignInSubmit: (values: FormikValues) => void;
};

const SignInForm = ({handleSignInSubmit}: Props): React.ReactElement => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [notice] = useNotice();
    const [formHasBeenSubmitted] = useState(false);

    const initialValues = {
        username: '',
        password: '',
    };

    const schema = yup.object({
        username: yup.string().label('Username').required(),
        password: yup.string().label('Password').required(),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={values => {
                setLoading(true);
                handleSignInSubmit(values);
                setLoading(false);
            }}
            enableReinitialize={true}
            validateOnChange={formHasBeenSubmitted}
            validateOnBlur={formHasBeenSubmitted}
        >
            {props => {
                const isValid = props.isValid;
                const handleSubmit = props.handleSubmit;

                return (
                    <Form
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <Stack sx={{mt: 2, mb: 3}}>
                            <Box sx={{textAlign: 'center', fontWeight: 600}}>
                                {t('sefAdminSignIn')}
                            </Box>
                            <ReactMarkdown className='markdown'>
                                {notice.logInMessage}
                            </ReactMarkdown>
                            <StandardField
                                name='username'
                                type='text'
                                label='Username:'
                                disabled={false}
                                stackSx={{mt: 2}}
                            />
                            <StandardField
                                name='password'
                                type='password'
                                label='Password:'
                                disabled={false}
                            />
                            <hr />
                            <Stack sx={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <Button
                                    color={!isValid ? 'error' : 'secondary'}
                                    variant='outlined'
                                    disabled={loading || !isValid}
                                    onClick={async () => props.submitForm()}
                                >
                                    {t('adminLogin')} &nbsp;
                                    <LoginIcon/>
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SignInForm;
