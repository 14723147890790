import {Stack} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type * as React from 'react';

const FullScreenLoadingIndicator = (): React.ReactElement => {
    return (
        <Stack
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <CircularProgress />
        </Stack>
    );
};

export default FullScreenLoadingIndicator;
