import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import type * as React from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
    show: boolean;
    handleClose: () => void;
    remaining: number;
};

const InactivityModal: React.FC<Props> = ({
    show,
    handleClose,
    remaining,
}: Props): React.ReactElement => {
    const {t} = useTranslation();
    const timeRemainingDisplay = new Date(remaining * 1000).toISOString().substring(15, 19);

    return (
        <>
            <Dialog open={show} onClose={handleClose}>
                <DialogTitle>{t('inactiveModalHeader')}</DialogTitle>
                <DialogContent>
                    {t('inactiveModalText')} {timeRemainingDisplay}
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' variant='outlined' onClick={handleClose}>
                        {t('inactiveModalButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InactivityModal;
