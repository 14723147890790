import {
    StudentEnrollmentForms,
    getNextStudentEnrollmentFormKey,
    getPreviousStudentEnrollmentFormKey,
} from '@/shared/enrollment.keys.ts';
import type {DeleteResponse, Document, Enrollment} from '@/shared/model.interface.ts';
import axios from 'axios';
import type {FormikConfig, FormikProps, FormikValues} from 'formik';
import {yupToFormErrors} from 'formik';
import type * as React from 'react';
import {createContext, type MutableRefObject, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import type {ValidationError} from 'yup';
import {fetchEnrollment, saveEnrollment, submitEnrollment} from '../../hooks/enrollment';
import useFormSchema from '../../hooks/useFormSchema';
import {validateAddress} from '../Helpers/validationHelpers';
import type {MutateError, StudentEnrollmentParams} from '../Pages/StudentEnrollment';
import type {JWTUser} from './JWTProvider';
import ErrorModal from "@/components/ErrorModal.tsx";

export type FormContextInterface = {
    enrollment: Enrollment | undefined;
    formRef: MutableRefObject<any>;
    userStep?: number | undefined;
    setUserStep: (userStep?: number) => void;
    handleFormSave: (values: FormikValues, nextAction?: string) => void;
    formSchema: FormikConfig<FormikProps<any>>;
    enrollmentSchema: any;
    formHasBeenSubmitted: boolean;
    setFormHasBeenSubmitted: (formHasBeenSubmitted: boolean) => void;
    setFieldModified: (fieldName?: string) => void;
    buildRequiredDocs: (
        enrollment: Enrollment,
        formValues: FormikValues,
        step: string,
        formRef: MutableRefObject<any>,
    ) => Document[];
    handleAddDocument: (documentToAdd: Document, step: string, documentType: string) => Promise<void>;
    removeAttachmentFromParentForm: (documentId: string, step: string) => Promise<void>;
    validatePage: (formKey: number, enrollment: Enrollment) => Promise<boolean>;
    validateEnrollment: (enrollment: Enrollment) => Promise<boolean>;
    isProcessing: boolean;
};

const FormControlContext = createContext<FormContextInterface>({} as FormContextInterface);

type FormControlContextProviderProps = {
    authenticatedUser?: JWTUser | null;
    children: React.ReactNode;
};

const FormControlContextProvider = ({
    authenticatedUser,
    children,
}: FormControlContextProviderProps): React.ReactElement => {
    const formRef = useRef<any>(null);
    const {t} = useTranslation();
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
    const {getFormSchema} = useFormSchema();
    const {enrollmentId, step} = useParams<StudentEnrollmentParams>();
    const startingStep = step !== undefined ? Number.parseInt(step) : step;
    const [userStep, setUserStep] = useState<number | undefined>(startingStep);
    const [hasError, setHasError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // get form schema
    const formSchema = getFormSchema(userStep === undefined ? 100 : userStep);
    const enrollmentSchema = getFormSchema();

    // queries/mutations
    const {mutate: deleteDocument} = useMutation(async (documentId: string) =>
        axios.post(`${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/document/delete`, {
            documentId: documentId,
        }),
    );
    const {
        isLoading: isEnrollmentLoading,
        isError: isEnrollmentError,
        data: enrollment,
        error: enrollmentError,
    } = useQuery('enrollment', async () => fetchEnrollment(enrollmentId ?? ''), {
        onSuccess: enrollment => {
            if (enrollment.status === 'Under Review' || enrollment.status === 'Ready for Import') {
                navigate(`/dashboard/${enrollment.guardianId}`);
            }

            if (userStep === undefined) {
                setUserStep(enrollment.step);
            }
        },
    });

    const {mutate: updateEnrollment} = useMutation(saveEnrollment);
    const {mutate: submittedEnrollment} = useMutation(submitEnrollment);

    const setFieldModified = async (fieldName?: string) => {
        if (formRef.current && enrollment !== undefined) {
            if (fieldName) {
                await formRef.current.setTouched({fields: {fieldName: true}});
                await formRef.current.setTouched({fields: {id: true}});
            } else {
                await formRef.current.setTouched({fields: {id: true}});
            }
        }
    };

    const buildRequiredDocs = useCallback(
        (
            enrollment: Enrollment,
            formValues: FormikValues,
            step: string,
            formRef: any,
        ) => {
            const documents = enrollment.documents.filter(document => document.step === step);
            const requiredDocs: Document[] = [];

            if (step === '205') {
                if (formValues.divorcedOrSeparated === 'true') {
                    const foundExistingDoc = documents.filter(
                        document => document.documentType === 'Parenting Agreement',
                    );

                    if (foundExistingDoc.length) {
                        requiredDocs.push(...foundExistingDoc);
                    } else {
                        requiredDocs.push({
                            id: undefined,
                            fileId: undefined,
                            name: undefined,
                            hasBeenSaved: false,
                            step: step,
                            documentType: 'Parenting Agreement',
                            willDropOffOrMailIn: false,
                        });
                    }
                }
            } else if (step === '400') {
                if (
                    formValues.birthCertificateAvailability === '1' ||
                    formValues.birthCertificateAvailability === 'true' ||
                    formValues.birthCertificateAvailability === true
                ) {
                    const foundExistingDoc = documents.filter(
                        document => t('sefBirthCert') === document.documentType,
                    );

                    if (foundExistingDoc.length) {
                        requiredDocs.push(...foundExistingDoc);
                    } else {
                        requiredDocs.push({
                            id: undefined,
                            fileId: undefined,
                            name: undefined,
                            hasBeenSaved: false,
                            step: step,
                            documentType: t('sefBirthCert'),
                            willDropOffOrMailIn: false,
                        });
                    }
                }

                if (
                    formValues.birthCertificateAvailability === false ||
                    formValues.birthCertificateAvailability === 'false'
                ) {
                    const foundExistingDoc = documents.filter(
                        document => t('sefOtherEvidence') === document.documentType,
                    );

                    if (foundExistingDoc.length) {
                        requiredDocs.push(...foundExistingDoc);
                    } else {
                        requiredDocs.push({
                            id: undefined,
                            fileId: undefined,
                            name: undefined,
                            hasBeenSaved: false,
                            step: step,
                            documentType: t('sefOtherEvidence'),
                            willDropOffOrMailIn: false,
                        });
                    }
                }
            } else if (step === '501') {
                if (
                    ((formValues.livingSituation === 'Homeowner' ||
                        formValues.livingSituation === 'Renter') &&
                        formValues.residencySecondary !== '') ||
                    formValues.livingSituation === 'Sharing' ||
                    formValues.livingSituation === 'Homeless'
                ) {
                    const documentType = formValues.residencySecondary === ''
                        ? 'Statement from Landlord'
                        : formValues.residencySecondary;
                    const foundOtherExistingDoc = documents.filter(
                        document => document.documentType === documentType
                    );
                    if (foundOtherExistingDoc.length) {
                        requiredDocs.push(...foundOtherExistingDoc);
                    } else {
                        requiredDocs.push({
                            id: undefined,
                            fileId: undefined,
                            name: undefined,
                            hasBeenSaved: false,
                            step: step,
                            documentType,
                            willDropOffOrMailIn: false,
                        });
                    }
                }

                if (
                    Array.isArray(formValues.residencyTertiary) &&
                    formValues.residencyTertiary.length
                ) {
                    for (const residency of formValues.residencyTertiary) {
                        const foundExistingDoc = documents.filter(
                            document => residency === document.documentType,
                        );

                        if (foundExistingDoc.length) {
                            requiredDocs.push(...foundExistingDoc);
                        } else {
                            requiredDocs.push({
                                id: undefined,
                                fileId: undefined,
                                name: undefined,
                                hasBeenSaved: false,
                                step: step,
                                documentType: residency,
                                willDropOffOrMailIn: false,
                            });
                        }
                    }
                }
            }

            if (formRef?.current) {
                const newFormValues = {...formValues};
                newFormValues[`documents${step}`] = requiredDocs;
                formRef.current.setFieldValue(`documents${step}`, requiredDocs);
            } else {
                console.error(
                    'tying to set docs without a form ref',
                    requiredDocs,
                    `documents${step}`,
                );
            }

            return requiredDocs;
        },
        [t],
    );

    const showAllErrors = async (values: Enrollment) => {
        await formSchema
            .validate(values, {abortEarly: false})
            .then(() => {
                // Success
            })
            .catch((err: ValidationError) => {
                yupToFormErrors(err);
            });
    };

    const validateEnrollment = async (enrollment: Enrollment) => {
        let isValid = true;

        for (const formKey of StudentEnrollmentForms) {
            const pageIsValid = await validatePage(formKey, enrollment);

            if (!pageIsValid) {
                isValid = false;
            }
        }

        return isValid;
    };

    const validatePage = async (formKey: number, enrollment: Enrollment) => {
        let isValid = true;

        if (enrollment) {
            const formSchema = getFormSchema(formKey);
            const values = formSchema?.cast(enrollment);
            isValid = await formSchema?.isValid(values, {abortEarly: false});

            if (!isValid) {
                await showAllErrors(values);
            }
        }

        return isValid;
    };

    const handleFormSave = useCallback(
        async (rawValues: FormikValues, submitType?: string) => {
            // @todo: find a better fix
            if (rawValues.unableToProvideDocumentsSituations === '') {
                rawValues.unableToProvideDocumentsSituations = [];
            }

            let values = await formSchema?.cast(rawValues);
            const formErrors = await formRef.current?.validateForm();

            const hasFormErrors = Object.keys(formErrors).length > 0;

            if (import.meta.env.VITE_REACT_APP_USPS_VALIDATION === 'enabled') {
                const updatedAddress = await validateAddress(
                    values.address,
                    values.city,
                    values.state,
                    values.zip,
                );
                values = {
                    ...values,
                    address: updatedAddress.updatedAddress,
                    city: updatedAddress.updatedCity,
                    state: updatedAddress.updatedState,
                    zip: updatedAddress.updatedZip,
                    studentInformationUSPSValidated: updatedAddress.isValid,
                };

                if (values.guardians) {
                    if (values.guardians.length > 0) {
                        const guardiansArray = values.guardians;

                        for (
                            let guardianIndex = 0;
                            guardianIndex < guardiansArray.length;
                            guardianIndex++
                        ) {
                            const updatedAddress = await validateAddress(
                                guardiansArray[guardianIndex].address,
                                guardiansArray[guardianIndex].city,
                                guardiansArray[guardianIndex].state,
                                guardiansArray[guardianIndex].zip,
                            );
                            guardiansArray[guardianIndex] = {
                                ...guardiansArray[guardianIndex],
                                address: updatedAddress.updatedAddress,
                                city: updatedAddress.updatedCity,
                                state: updatedAddress.updatedState,
                                zip: updatedAddress.updatedZip,
                                guardianInformationUSPSValidated: updatedAddress.isValid,
                            };
                        }

                        values = {
                            ...values,
                            guardians: guardiansArray,
                        };
                    }
                }
            }

            //The (submitType === 'continue' && hasFormErrors) part of the condition will never happen;
            // handleSave doesn't get called if the continue button is clicked while the form has an error;
            if (submitType === 'save' || (submitType === 'continue' && hasFormErrors)) {
                setFormHasBeenSubmitted(true);
            } else {
                setFormHasBeenSubmitted(false);
            }

            if ((submitType === 'continue' || submitType === 'submit') && hasFormErrors) {
                //@TODO consider making a similar return (although I need to account for saving other data)
                return;
            }

            if (!hasFormErrors && values.status === 'Submitted: Awaiting Documents') {
                // check to be sure all documents are uploaded
                // values.status = 'Submitted'
            }

            setHasError(false);

            if (enrollment !== undefined && userStep !== undefined) {
                const nextstep = getNextStudentEnrollmentFormKey(userStep);
                const prevstep = getPreviousStudentEnrollmentFormKey(userStep);

                const newEnrollment = {
                    ...enrollment,
                    ...values,
                    documents: [
                        ...(values.documents205 ?? []),
                        ...(values.documents400 ?? []),
                        ...(values.documents501 ?? []),
                    ],
                };

                newEnrollment.documents205 = undefined;
                newEnrollment.documents400 = undefined;
                newEnrollment.documents501 = undefined;

                if (
                    submitType === 'continue' &&
                    nextstep !== undefined &&
                    nextstep > enrollment.step
                ) {
                    newEnrollment.step = nextstep;
                }

                if (submitType === 'submit' && !hasFormErrors) {
                    if (authenticatedUser === null) {
                        const outstandingDocuments = newEnrollment.documents.filter(
                            (document: Document) => document.willDropOffOrMailIn,
                        );

                        if (outstandingDocuments?.length) {
                            newEnrollment.status = 'Submitted: Awaiting Documents';
                        } else {
                            newEnrollment.status = 'Submitted';
                        }

                        newEnrollment.dateSubmitted = new Date();
                    }
                }

                updateEnrollment(newEnrollment as Enrollment, {
                    onSuccess: (returnedEnrollment: Enrollment) => {
                        if ([205, 400, 501].includes(userStep)) {
                            const currentPageDocuments = returnedEnrollment.documents?.filter(
                                (document: Document) => document.step === userStep.toString(),
                            );

                            values[`documents${userStep}`] = currentPageDocuments;

                            if (userStep === 205) {
                                returnedEnrollment.documents205 = currentPageDocuments;
                            }

                            if (userStep === 400) {
                                returnedEnrollment.documents400 = currentPageDocuments;
                            }

                            if (userStep === 501) {
                                returnedEnrollment.documents501 = currentPageDocuments;
                            }

                            buildRequiredDocs(
                                returnedEnrollment,
                                values,
                                userStep.toString(),
                                formRef,
                            );
                        }

                        // update the cache
                        queryClient.setQueryData(['enrollment'], returnedEnrollment as Enrollment);

                        // navigate
                        if (submitType === 'continue' && nextstep !== undefined && !hasFormErrors) {
                            setUserStep(nextstep);
                        } else if (submitType === 'previous' && prevstep !== undefined) {
                            setUserStep(prevstep);
                        } else if (
                            submitType === 'close' &&
                            enrollment &&
                            enrollment.guardianId !== undefined
                        ) {
                            if (authenticatedUser !== null) {
                                navigate('/admin-dashboard');
                            } else {
                                navigate(`/dashboard/${enrollment.guardianId}`);
                            }
                        } else if (submitType === 'submit') {
                            submittedEnrollment(returnedEnrollment as Enrollment);

                            if (authenticatedUser !== null) {
                                navigate('/admin-dashboard');
                            } else {
                                navigate(`/dashboard/${enrollment.guardianId}`);
                            }
                        } else if (submitType === undefined) {
                            formRef.current?.setErrors({});
                        }
                    },
                    onError: (e) => {
                        console.error('validation error', e);
                        setErrorMessage('saveError');
                        setHasError(true);
                    },
                });
            }
        },
        [
            enrollment,
            updateEnrollment,
            userStep,
            queryClient,
            navigate,
            formSchema,
            authenticatedUser,
            buildRequiredDocs,
            submittedEnrollment,
        ],
    );

    const handleAddDocument = async (documentToAdd: Document, step: string, documentType: string) => {
        setErrorMessage('');
        setHasError(false);

        if (formRef.current) {
            const newFormValues = {...formRef.current.values};
            const newEnrollment = {...enrollment};

            newFormValues[`documents${step}`] = newFormValues[`documents${step}`].map(
                (formDoc: Document) => {
                    if (formDoc.documentType === documentType) {
                        formDoc.hasBeenSaved = true;
                        formDoc.id = documentToAdd.id;
                        formDoc.fileId = documentToAdd.fileId;
                        formDoc.name = documentToAdd.name;
                    }

                    return formDoc;
                },
            );

            queryClient.setQueryData(['enrollment'], newEnrollment);
            formRef.current.setValues(newFormValues);
            await handleFormSave(newFormValues);
        } else {
            console.error('handleAddDocument with null form ref');
        }
    };

    const removeAttachmentFromParentForm = async (documentId: string, step: string) => {
        setErrorMessage('');
        setHasError(false);
        setIsProcessing(true);

        if (documentId) {
            deleteDocument(documentId, {
                onSuccess: (response: DeleteResponse) => {
                    // update the current form values
                    const newFormValues = {...formRef.current?.values};
                    newFormValues[`documents${step}`] = newFormValues[`documents${step}`].map(
                        (document: Document) => {
                            if (document.id === documentId) {
                                return response.data;
                            }

                            return document;
                        },
                    );
                    formRef.current?.setValues(newFormValues);

                    // update the query cache, clear the fileId and name
                    const newEnrollment = {...enrollment};
                    const newDocuments = [...(newEnrollment.documents || [])];
                    newEnrollment.documents = newDocuments.map((document: Document) => {
                        if (document.id === response.data.id) {
                            document.fileId = undefined;
                            document.name = undefined;
                        }

                        return document;
                    });

                    queryClient.setQueryData(['enrollment'], newEnrollment);
                    setIsProcessing(false);

                    handleFormSave(newFormValues);
                },
                onError: () => {
                    setErrorMessage(t('errorRemoveAttachment'));
                    setHasError(true);
                    setIsProcessing(false);
                },
            });
        } else {
            setErrorMessage(t('errorDocumentId'));
            setHasError(true);
        }
    };

    useEffect(() => {
        if (userStep !== undefined) {
            window.scrollTo(0, 0);
        }
    }, [userStep]);

    if (isEnrollmentLoading) {
        return <div>Loading ...</div>;
    }

    if (isEnrollmentError || enrollment === undefined) {
        const error = enrollmentError as MutateError;
        return <div>There has been an error fetching this enrollment. {error.message}</div>;
    }

    const formContext: FormContextInterface = {
        enrollment,
        formRef,
        formSchema,
        enrollmentSchema,
        userStep,
        setUserStep,
        handleFormSave,
        setFormHasBeenSubmitted,
        formHasBeenSubmitted,
        setFieldModified,
        buildRequiredDocs,
        handleAddDocument,
        removeAttachmentFromParentForm,
        validatePage,
        validateEnrollment,
        isProcessing,
    };

    return <>
        <FormControlContext.Provider value={formContext}>{children}</FormControlContext.Provider>
        <ErrorModal
            show={hasError}
            handleClose={() => {setHasError(false)}}
            errorMessage={errorMessage}
        />
    </>;
};

const useFormControlContext = (): FormContextInterface => {
    const context = useContext(FormControlContext);

    if (Object.keys(context).length === 0) {
        throw new Error('useFormControlContext must be used within a FormControlContextProvider');
    }

    return context;
};

export {FormControlContextProvider, FormControlContext, useFormControlContext};
