import {Alert} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';

type Props = {
    showAlert: boolean;
    setShowAlert: (showAlert: boolean) => void;
    message: string;
};

const DismissibleAlert = ({showAlert, setShowAlert, message}: Props): React.ReactElement => {
    return (
        <>
            {showAlert && (
                <Alert
                    icon={false}
                    variant={'standard'}
                    color={'error'}
                    onClose={() => {
                        setShowAlert(false);
                    }}
                >
                    {message && <Typography>{message}</Typography>}
                </Alert>
            )}
        </>
    );
};

export default DismissibleAlert;
