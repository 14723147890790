import {useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import InactivityModal from './InactivityModal';
import {signOut} from './Providers/JWTProvider';

const timeout = Number.parseInt(import.meta.env.VITE_REACT_APP_TIMEOUT_MILLISECONDS);
const promptBeforeIdle = 60 * 1000;

const InactivityTimer = () => {
    const [remaining, setRemaining] = useState<number>(timeout);
    const [open, setOpen] = useState<boolean>(false);

    const onIdle = () => {
        setOpen(false);
        signOut();
        window.location.href = '/';
    };

    const onActive = () => {
        setOpen(false);
    };

    const onPrompt = () => {
        setOpen(true);
    };

    const {getRemainingTime, activate} = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime]);

    const handleStillHere = () => {
        activate();
    };

    return <InactivityModal show={open} handleClose={handleStillHere} remaining={remaining} />;
};

export default InactivityTimer;
