import type {SxProps} from '@mui/material';
import {FormLabel, Stack} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import type {FormikValues} from 'formik';
import {Field, useFormikContext} from 'formik';
import type {FormikContextType} from 'formik/dist/types';
import  type {ChangeEvent} from 'react';
import MaskedInput from 'react-text-mask';
import type * as React from "react";
import type {FieldProps} from 'formik';

type CustomFieldProps<T> = FieldProps<T> & {
    name: string;
    value: string;
};

const phoneNumberMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

type Props = {
    label: string;
    name: string;
    arrayName?: string;
    iteration?: number;
    stackSx?: SxProps;
};

const PhoneField = ({label, name, arrayName, iteration, stackSx}: Props) : React.ReactElement => {
    const {values, handleChange, errors, setFieldTouched}: FormikContextType<FormikValues> = useFormikContext();
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const fieldValue = arrayName && iteration !== undefined ? values[arrayName][iteration][name] : values[name];

    // const errorsName =
    //     arrayName && iteration !== undefined && errors[arrayName] && errors[arrayName][iteration]
    //         ? errors[arrayName][iteration][name]
    //         : errors[fieldName];

    let errorsName: string | undefined;

    if (errors[fieldName]) {
        errorsName = errors[fieldName] as string
    }

    if (arrayName !== undefined && iteration !== undefined) {
        const errorsArr = errors[arrayName] as Record<string, string>[] | undefined;

        if (errorsArr) {
            for (let i = 0; i < errorsArr.length; i++) {
                if (errorsArr[i] === undefined) {
                    continue;
                }

                if (i === iteration && errorsArr[i][name] !== null) {
                    errorsName = errorsArr[iteration][name];
                }
            }
        }
    }

    return (
        <Stack sx={stackSx}>
            {label && (
                <FormLabel sx={{
                    fontSize: '12.8px',
                    fontWeight: 600,
                    color: 'rgb(124, 124, 125)',
                    mb: 1,
                }}>
                    {label}&nbsp;
                </FormLabel>
            )}
            <Field
                type='text'
                name={fieldName}
                value={fieldValue === null ? '' : fieldValue}
                isInvalid={errorsName !== undefined}
            >{({field} : {field: CustomFieldProps<FormikValues>}) => {
                return <MaskedInput
                    value={field.value}
                    name={field.name}
                    mask={phoneNumberMask}
                    placeholder='xxx-xxx-xxxx'
                    type='text'
                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);

                        if (setFieldTouched) {
                            await setFieldTouched(fieldName);
                        }
                    }}
                    className={`form-control ${errorsName !== undefined ? 'is-invalid' : ''}`}
                    style={{
                        padding: '5px',
                        fontSize: '14px',
                        border: (errorsName !== undefined ? '1px solid red' : '1px solid #ccc'),
                        borderRadius: '4px',
                    }}
                />
            }}</Field>
            <Typography
                sx={{color: 'rgb(220, 53, 69)', mt: 1, mb: 2, fontSize: '14px', fontWeight: 400}}
            >
                {errorsName ? errorsName : ''}
            </Typography>
        </Stack>
    );
};

export default PhoneField;
