import {Button} from '@mui/material';
import type * as React from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
    callback: () => void;
};

const GreyButton = ({callback}: Props): React.ReactElement => {
    const {t} = useTranslation();

    return (
        <Button
            variant='outlined'
            onClick={() => {
                callback();
            }}
            sx={{
                backgroundColor: '#828282',
                color: '#000',
                border: '1px solid #888',
            }}
        >
            {t('back')} &nbsp;
        </Button>
    );
};

export default GreyButton;
