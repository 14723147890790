import {SnackbarProvider} from 'notistack';
import {Suspense, lazy} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import InactivityTimer from './components/InactivityTimer';
import LandingPage from './components/Pages/LandingPage';
import NotFound404 from './components/Pages/NotFound404';
import {ScheduledMaintenance} from './components/Pages/ScheduledMaintenance';
import SignIn from './components/Pages/SignIn';
import SignOut from './components/Pages/SignOut';
import JWTProvider from './components/Providers/JWTProvider';
import ScrollToTop from "@/components/ScrollToTop.tsx";

const AdminEnrollmentDashboard = lazy(
    async () => import('./components/Pages/AdminEnrollmentDashboard'),
);
const PdfViewerPage = lazy(async () => import('./components/Pages/PdfViewerPage'));
const DownloadPdf = lazy(async () => import('./components/Pages/DownloadPdf'));
const StudentEnrollment = lazy(async () => import('./components/Pages/StudentEnrollment'));
const EnrollmentDashboard = lazy(async () => import('./components/Pages/EnrollmentDashboard'));

const App = () => {
    return (
        <SnackbarProvider maxSnack={3}>
            <QueryClientProvider
                client={
                    new QueryClient({
                        defaultOptions: {
                            queries: {
                                retry: 1,
                                refetchOnWindowFocus: false,
                            },
                        },
                    })
                }
            >
                <BrowserRouter>
                    <ScrollToTop/>
                    <ScheduledMaintenance>
                        <Routes>
                            <Route path='/'>
                                <Route index element={<LandingPage />} />
                            </Route>

                            <Route path='/dashboard/:guardianId/:firstLoad?/:languageCode?'>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <EnrollmentDashboard />
                                        </Suspense>
                                    }
                                />
                            </Route>

                            <Route path='/enrollment/:enrollmentId/:step?'>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <StudentEnrollment />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route path='/signout' element={<SignOut />} />
                            <Route path='/admin-dashboard'>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <JWTProvider>
                                                <AdminEnrollmentDashboard />
                                                <InactivityTimer />
                                            </JWTProvider>
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route path='/view-pdf/:enrollmentId/:languageCode?'>
                                <Route
                                    index
                                    element={
                                        <JWTProvider>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <PdfViewerPage />
                                            </Suspense>
                                        </JWTProvider>
                                    }
                                />
                            </Route>
                            <Route path='/admin-pdf/:enrollmentId/:languageCode/:type?'>
                                <Route
                                    index
                                    element={
                                        <JWTProvider>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <DownloadPdf />
                                            </Suspense>
                                        </JWTProvider>
                                    }
                                />
                            </Route>
                            <Route path='/signin' element={<SignIn />} />
                            <Route path='*' element={<NotFound404 />} />
                        </Routes>
                    </ScheduledMaintenance>
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </SnackbarProvider>
    );
};

export default App;
