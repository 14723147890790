import {InputLabel} from '@mui/material';
import type * as React from 'react';

type Props = {
    children: string | string[];
};

const FormLabel = ({children}: Props): React.ReactElement => {
    return (
        <InputLabel
            sx={{
                color: '#7c7c7d',
                fontWeight: 600,
                fontSize: '0.8em',
                mb: 1,
                textWrap: 'wrap',
            }}
        >
            {children}
        </InputLabel>
    );
};

export default FormLabel;
