import type {ExportEnrollment} from '@/components/ExportEnrollmentsButton.tsx';
import type {RaceReportRow} from '@/components/ImportEnrollmentsButton.tsx';
import axios from 'axios';
import type {JWTUser} from '../components/Providers/JWTProvider';
import type {
    Enrollment,
    EnrollmentData,
    EnrollmentExport,
    EnrollmentYear,
    Webuser,
} from '../shared/model.interface';
import formatDateOrString from "@/functions/formatDateOrString.ts";

type DoEnrollmentStudentProps = {
    guardianId: string;
    year: string;
};

export const doToggleEnrollmentYearActive = async ({id, active}: {id: string; active: boolean}) => {
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment-year/toggle-active`,
        {id: id, active: active},
    );
    return response.data;
};

export const doEnrollmentStudent = async ({
    guardianId,
    year,
}: DoEnrollmentStudentProps): Promise<Enrollment> => {
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment/create/${guardianId}`,
        {year: year},
    );
    return response.data;
};

type doResendEnrollmentEmailProps = {
    email: string;
    languageCode: string;
};

export const doResendEnrollmentEmail = async ({
    email,
    languageCode,
}: doResendEnrollmentEmailProps): Promise<Enrollment> => {
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/webuser/resend/`,
        {
            email: email,
            languageCode: languageCode,
        },
    );
    return response.data;
};

export const fetchEnrollment = async (id: string): Promise<Enrollment> => {
    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment/fetch/${id}`,
    );
    return response.data;
};

export const fetchEnrollmentYearData = async (): Promise<EnrollmentYear[]> => {
    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment-year/fetch/`,
    );
    return response.data;
};

export const fetchAdminEnrollmentYearData = async () => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/enrollment-years`,
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};


export const fetchEnrollmentDataForGuardian = async (id: string): Promise<Enrollment[]> => {
    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment/fetch-all/${id}`,
    );
    response.data.documents = [];
    return response.data;
};

export const fetchEnrollmentDataForAdmin = async (
    resultsPerPage: number,
    page: number,
    sortField: string,
    sortOrder: string,
    enrollmentStatus: string,
    enrollmentFilter: string,
): Promise<EnrollmentData> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    const newEnrollmentStatus = enrollmentStatus === undefined ? 'all' : enrollmentStatus;

    // catch 404s
    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.get(
        `${
            import.meta.env.VITE_REACT_APP_API_ENDPOINT
        }/v1/admin/fetch/${resultsPerPage}/${page}/${sortField}/${sortOrder}/${newEnrollmentStatus}/${enrollmentFilter}`,
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};

export const deleteEnrollment = async (enrollmentId: string) => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.delete(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/delete/${enrollmentId}`,
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};

export const fetchEnrollmentAccountsForAdmin = async (): Promise<Webuser[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    // catch 404s
    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/fetch/webusers`,
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};

export const saveEnrollment = async (enrollment: Enrollment): Promise<Enrollment> => {
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment/save/${enrollment.id}`,
        enrollment,
    );
    return response.data;
};

export const submitEnrollment = async (enrollment: Enrollment): Promise<string> => {
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment/submit/${enrollment.id}`,
        enrollment,
    );
    return response.data;
};

export const saveEnrollmentNote = async (enrollment: Enrollment): Promise<Enrollment[]> => {
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/enrollment/save-note/${enrollment.id}`,
        {note: enrollment.note},
    );
    return response.data;
};

export const saveEnrollmentStudentId = async (enrollment: Enrollment): Promise<Enrollment[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    // catch 404s
    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/save/studentId/${enrollment.id}`,
        {
            studentId: enrollment.studentId,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const saveEnrollmentStudentIsbeSisId = async (
    enrollment: Enrollment,
): Promise<Enrollment[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    // catch 404s
    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/save/studentIsbeSisId/${
            enrollment.id
        }`,
        {
            studentIsbeSisId: enrollment.studentIsbeSisId,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const saveEnrollmentStatus = async (enrollment: Enrollment): Promise<Enrollment[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    // catch 404s
    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/save/status/${enrollment.id}`,
        {
            status: enrollment.status,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const importEnrollments = async (data: {enrollmentIds: string[]; user: string}): Promise<
    Enrollment[]
> => {
    const {enrollmentIds, user} = data;
    const localStorageUser = localStorage.getItem('JWTUser');

    if (!localStorageUser) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(localStorageUser);
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/import`,
        {
            enrollmentIds: enrollmentIds,
            user: user,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const downloadImportedEnrollments = async (data: {
    enrollmentIds: string[];
    user: string;
}): Promise<Enrollment[]> => {
    const {enrollmentIds, user} = data;
    const localStorageUser = localStorage.getItem('JWTUser');

    if (!localStorageUser) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(localStorageUser);
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/download-imported`,
        {
            enrollmentIds: enrollmentIds,
            user: user,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};

export const downloadRaceReportData = async (data: {
    enrollmentIds: string[];
    user: string;
}): Promise<RaceReportRow[]> => {
    const {enrollmentIds, user} = data;
    const localStorageUser = localStorage.getItem('JWTUser');

    if (!localStorageUser) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(localStorageUser);
    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/download-race-report`,
        {
            enrollmentIds: enrollmentIds,
            user: user,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};

type ExportEnrollmentOverride = Omit<ExportEnrollment, 'fromDate' | 'toDate'> & {
    fromDate: Date | '';
    toDate: Date | '' | undefined;
};

export const exportEnrollments = async (data: ExportEnrollmentOverride): Promise<Enrollment[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/export`,
        {
            fromDate: formatDateOrString(data.fromDate !== '' ? data.fromDate : new Date()),
            toDate: formatDateOrString((data.toDate === '' || !data.toDate) ? new Date() : data.toDate),
            type: data.type,
        },
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const getImportEnrollments = async (): Promise<Enrollment[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);
    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/import/get`,
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const getExportEnrollments = async (): Promise<EnrollmentExport[]> => {
    const user = localStorage.getItem('JWTUser');

    if (!user) {
        throw Error('User Not Found.');
    }

    const localUser: JWTUser = JSON.parse(user);

    // catch 404s
    axios.interceptors.response.use(
        response => response,
        async error => {
            return Promise.reject(error);
        },
    );

    const response = await axios.get(
        `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/admin/export/get`,
        {
            headers: {
                Authorization: `Bearer ${localUser.accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};
