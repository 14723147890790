import type {ReactNode} from 'react';
import React, {useCallback, useContext} from 'react';

export type HomeMessageNotice = {
    message: string;
    show: boolean;
};

export type NoticeState = {
    loading: boolean;
    error: boolean;
    homeMessage: HomeMessageNotice;
    instructions: string;
    logInMessage: string;
};

type Props = {
    children?: ReactNode;
};

export const NoticeProviderContext = React.createContext<
    [NoticeState, (() => Promise<void>) | null]
>([
    {
        loading: false,
        error: false,
        homeMessage: {message: '', show: false},
        instructions: '',
        logInMessage: '',
    },
    null,
]);

const noticeReducer = (state: NoticeState, action: any) => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading: true, error: false};
        }

        case 'SET_NOTICES': {
            return {
                ...state,
                loading: true,
                error: false,
                homeMessage: action.homeMessage,
                instructions: action.instructions,
                logInMessage: action.logInMessage,
            };
        }

        case 'LOADED': {
            return {...state, loading: false, error: false};
        }

        case 'CLOSE_SYSTEM_NOTICE': {
            return {
                ...state,
                loading: false,
                error: false,
                homeMessage: {...state.homeMessage, show: false},
            };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const NoticeProvider: React.FC<Props> = ({children}: Props): React.ReactElement => {
    const [noticeState, setNoticeState] = React.useReducer(noticeReducer, {
        loading: false,
        error: false,
        homeMessage: {message: '', show: false},
        instructions: '',
        logInMessage: '',
    });

    const closeSystemNotice = useCallback(async () => {
        setNoticeState({
            type: 'CLOSE_SYSTEM_NOTICE',
        });
    }, []);

    return (
        <NoticeProviderContext.Provider value={[noticeState, closeSystemNotice]}>
            {children}
        </NoticeProviderContext.Provider>
    );
};

export const useNotice = (): [NoticeState, (() => Promise<void>) | null] => {
    return useContext(NoticeProviderContext);
};

export default NoticeProvider;
