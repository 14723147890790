import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export const StudentEnrollmentForms = [
    100, 200, 201, 202, 203, 204, 205, 206, 270, 280, 300, 400, 500, 501, 600, 700,
];

export const getNextStudentEnrollmentFormKey = (currentKey: number): number => {
    const currentIndex = StudentEnrollmentForms.indexOf(currentKey);
    return StudentEnrollmentForms[currentIndex + 1];
};

export const getPreviousStudentEnrollmentFormKey = (currentKey: number): number => {
    const currentIndex = StudentEnrollmentForms.indexOf(currentKey);

    if (currentIndex === 0) {
        return StudentEnrollmentForms[currentIndex];
    }

    return StudentEnrollmentForms[currentIndex - 1];
};

type ProgressBarState = {
    name: string;
    state: number;
    number: number;
    pageValid?: boolean;
};

export const useProgressKeys = () => {
    const {t} = useTranslation();

    const progressBarStates: ProgressBarState[] = useMemo(() => {
        return [
            {name: t('step1'), state: 100, number: 1},
            {name: t('step2'), state: 200, number: 2},
            {name: t('sef201'), state: 201, number: 2},
            {name: t('sef202'), state: 202, number: 2},
            {name: t('sef203'), state: 203, number: 2},
            {name: t('sef204'), state: 204, number: 2},
            {name: t('sef205'), state: 205, number: 2},
            {name: t('sef206'), state: 206, number: 2},
            {name: t('sef270'), state: 270, number: 2},
            {name: t('sef280'), state: 280, number: 2},
            {name: t('step3'), state: 300, number: 3},
            {name: t('step4'), state: 400, number: 4},
            {name: t('step500'), state: 500, number: 5},
            {name: t('step501'), state: 501, number: 5},
            {name: t('step6'), state: 600, number: 6},
            {name: t('step7'), state: 700, number: 7},
        ];
    }, [t]);

    return progressBarStates;
};
