import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import FullScreenLoadingIndicator from '../FullScreenLoadingIndicator';
import type {JWTUser} from '../Providers/JWTProvider';
import {signOut} from '../Providers/JWTProvider';

const SignOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('JWTUser');

        const localUser: JWTUser = JSON.parse(user as string);

        if (localUser !== null) {
            signOut();
            navigate('/signin');
        } else {
            navigate('/');
        }
    }, [navigate]);

    return <FullScreenLoadingIndicator />;
};

export default SignOut;
