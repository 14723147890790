import {Stack} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography/Typography';
import type {ReactNode} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Navbar from '../Navigation/Navbar';

const OK = 1;
const MISSING = 2;
const FAILED_FETCH = -1;
const BAD_RESULT = -2;

const states = [OK, FAILED_FETCH, BAD_RESULT, MISSING];

type Status = {
    state: (typeof states)[number];
    start?: string;
    startDate?: Date;
    end?: string;
    endDate?: Date;
};

function isStatus(value: unknown): value is Status {
    if (typeof value !== 'object' || value === null || !('state' in value)) {
        return false;
    }

    const stateType = typeof value.state;
    return stateType === 'number' && states.includes(value.state as (typeof states)[number]);
}

const refreshStatus = async (): Promise<Status> => {
    try {
        const result = await fetch('/status.json');

        if (!result.ok) {
            return {state: FAILED_FETCH};
        }

        const contentType = result.headers.get('content-type');

        if (contentType === 'text/html') {
            return {state: MISSING};
        }

        const status = (await result.json()) as unknown;

        if (!isStatus(status)) {
            return {state: BAD_RESULT};
        }

        return status;
    } catch (e) {
        return {state: FAILED_FETCH};
    }
};

const Layout = ({children}: {children: ReactNode}) => {
    const {t} = useTranslation();

    return (
        <>
            <Navbar showSignOut={false}/>
            <Container sx={{mt: '117px'}}>
                <Stack sx={{mt: 3}}>
                    <Typography
                        variant='body1'
                        sx={{
                            mb: 3,
                            pt: 2,
                            width: '50%',
                            color: '#fff',
                            fontWeight: 600,
                        }}
                    >
                        {t('sef')}
                    </Typography>
                </Stack>
                <Stack sx={{backgroundColor: '#fff', p: 3, mt: 0, mb: 5}}>{children}</Stack>
            </Container>
        </>
    );
};

export const ScheduledMaintenance = ({children}: {children: ReactNode}): ReactNode => {
    const [status, setStatus] = useState<Status | null>(null);
    const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

    useEffect(() => {
        if (status?.end && status.start) {
            if (!status.endDate || !status.startDate) {
                setIsMaintenance(false);
                setStatus({
                    ...status,
                    startDate: new Date(status.start),
                    endDate: new Date(status.end),
                });
                return;
            }

            const now = new Date();
            setIsMaintenance(now >= status.startDate && now <= status.endDate);
            //every 10 seconds check if we are between the dates
            const interval = setInterval(() => {
                if (!status.startDate || !status.endDate) {
                    setIsMaintenance(false);
                    return;
                }

                const now = new Date();
                setIsMaintenance(now >= status.startDate && now <= status.endDate);
            }, 10_000); //

            return () => {
                clearInterval(interval);
            };
        }
    }, [status]);

    useEffect(() => {
        refreshStatus()
            .then(status => {
                setStatus(status);
            })
            .catch(() => {
                setStatus({state: FAILED_FETCH});
            });

        //every 5 minutes fetch the maintenance json
        const interval = setInterval(async () => {
            const status = await refreshStatus();
            setStatus(status);
        }, 300_000); //5 minutes

        return () => {
            clearInterval(interval);
        };
    }, []);

    if (isMaintenance) {
        return (
            <Layout>
                The site is currently offline for maintenance and is scheduled to be back online at{' '}
                {status?.endDate?.toLocaleString()}.
            </Layout>
        );
    }

    return <>{children}</>;
};
