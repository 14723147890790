import type React from 'react';
import {Box, Stack, type SxProps} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

type Props = {
    sx ?: SxProps;
};

const SignOutButton = ({sx} : Props) : React.ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return <Stack
        sx={{
            flexDirection: 'column',
            justifyContent: 'flex-end',
            px: 2,
            ...sx,
        }}
        onClick={() => {
            navigate('/signout');
        }}
    >
        <Stack
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
            }}
        >
            <LogoutIcon
                sx={{
                    color: '#737373',
                    mb: 1,
                }}
            />

            <Box sx={{pt: 1, color: '#737373 !important'}}>
                {t('logout')}
            </Box>
        </Stack>
    </Stack>
}

export default SignOutButton;
