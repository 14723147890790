import StackColumn from '@/components/Stacks/StackColumn.tsx';
import StackRow from '@/components/Stacks/StackRow.tsx';
import {Box, Button, Container, Stack} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import type {AxiosResponse} from 'axios';
import axios from 'axios';
import type {FormikValues} from 'formik';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import ContinueEnrollmentForm from '../Forms/ContinueEnrollmentForm';
import CreateEnrollmentForm from '../Forms/CreateEnrollmentForm';
import Navbar from '../Navigation/Navbar';
import VideoTutorialModal from '../VideoTutorialModal';

const LandingPage = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [hasError, setHasError] = useState(false);
    const [hasMessage, setHasMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showCreateEnrollment, setShowCreateEnrollment] = useState(false);
    const [showContinueEnrollment, setShowContinueEnrollment] = useState(false);
    const [videoTutorialModal, setVideoTutorialModal] = useState<boolean>(false);
    const {mutate: createEnrollment} = useMutation(async (formValues: FormikValues) =>
        axios.post(
            `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/v1/webuser/create/${i18n.language}`,
            formValues,
        ),
    );

    const handleCreateEnrollmentFormSubmit = useCallback(
        async (values: FormikValues) => {
            createEnrollment(values, {
                onSuccess: (result: AxiosResponse) => {
                    const possibleMessage = result.data.message;

                    if (possibleMessage && possibleMessage === 'Email exists.') {
                        setMessage(t('resentConfirmation'));
                        setHasMessage(true);
                    } else {
                        navigate(`/dashboard/${result.data.id}/true`);
                    }
                },
                onError: (error: unknown) => {
                    if (axios.isAxiosError(error) && error.response) {
                        setMessage(
                            `Your user could not be created. ERROR: ${error.response.data.hint}`,
                        );
                        setHasError(true);
                    }
                },
            });
        },
        [createEnrollment, navigate, t],
    );

    return (
        <>
            <VideoTutorialModal
                open={videoTutorialModal}
                setOpen={setVideoTutorialModal}
                videoID={import.meta.env.VITE_REACT_APP_ENROLLMENT_VIDEO_ID}
                modalTitle={'howToEnroll'}
            />
            <Navbar showSignOut={false}/>
            <Container sx={{mt: '117px'}}>
                <StackColumn>
                    <StackRow>
                        <Typography
                            variant='body1'
                            sx={{
                                mb: 3,
                                pt: 2,
                                width: '50%',
                                color: '#fff',
                                fontWeight: 600,
                            }}
                        >
                            {t('sef')}
                        </Typography>
                    </StackRow>
                    <StackRow
                        sx={{borderRadius: '8px', backgroundColor: '#fff', px: 2, mt: 0, mb: 5}}
                    >
                        <>
                            {!(showCreateEnrollment || showContinueEnrollment) && (
                                <StackColumn sx={{minHeight: '400px', alignItems: 'center'}}>
                                    <StackRow sx={{mt: 5, mx: 3}}>
                                        <Typography variant={'body1'} sx={{textAlign: 'center'}}>
                                            {t('welcome')}
                                        </Typography>
                                    </StackRow>
                                    <StackRow sx={{mt: 3, mx: 3}}>
                                        <Typography variant={'body1'} sx={{textAlign: 'center'}}>
                                            {t('continue1')} <b>{t('continue2')}</b> {t('button')}.
                                        </Typography>
                                    </StackRow>
                                    <StackRow sx={{mt: 3, mx: 3}}>
                                        <Typography variant={'body1'} sx={{textAlign: 'center'}}>
                                            {t('create1')} <b>{t('create2')}</b> {t('button')}.
                                        </Typography>
                                    </StackRow>
                                    <Stack sx={{flexDirection: {xs: 'column', md: 'row'}, mt: 3}}>
                                        <Button
                                            color='secondary'
                                            variant='outlined'
                                            onClick={() => {
                                                setShowCreateEnrollment(true);
                                            }}
                                            sx={{
                                                mb: {xs: 3, md: 0},
                                                width: '240px',
                                            }}
                                        >
                                            {t('btnCreate')}
                                        </Button>
                                        <Box
                                            sx={{
                                                display: {xs: 'none', md: 'block'},
                                                mx: 3,
                                                height: '30px',
                                                borderRight: '1px solid black',
                                            }}
                                        />
                                        <Button
                                            color='secondary'
                                            variant='outlined'
                                            onClick={() => {
                                                setShowContinueEnrollment(true);
                                            }}
                                            sx={{
                                                width: '240px',
                                            }}
                                        >
                                            {t('continueEnrollment')}
                                        </Button>
                                    </Stack>
                                    <StackRow sx={{mt: 3, mb: 3}}>
                                        <Button
                                            color='secondary'
                                            variant='outlined'
                                            onClick={() => {
                                                setVideoTutorialModal(true);
                                            }}
                                        >
                                            {t('btnViewEnrollmentTutorial')}
                                        </Button>
                                    </StackRow>
                                </StackColumn>
                            )}
                            {showCreateEnrollment && (
                                <CreateEnrollmentForm
                                    handleBack={() => {
                                        setShowCreateEnrollment(false);
                                    }}
                                    handleCreateEnrollmentFormSubmit={
                                        handleCreateEnrollmentFormSubmit
                                    }
                                    hasMessage={hasMessage}
                                    setHasMessage={setHasMessage}
                                    message={message}
                                    hasError={hasError}
                                />
                            )}
                            {showContinueEnrollment && (
                                <ContinueEnrollmentForm
                                    handleBack={() => {
                                        setShowContinueEnrollment(false);
                                    }}
                                />
                            )}
                        </>
                    </StackRow>
                </StackColumn>
            </Container>
        </>
    );
};

export default LandingPage;
