import type React from 'react';
import {useEffect, useState} from 'react';
import {Box, MenuItem, Select, Stack, type SxProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import availableLanguages from "@/i18n/generatedTranslations/availableLanguage.json";
import type {DashboardParams} from "@/components/Pages/EnrollmentDashboard.tsx";

type Props = {
    sx ?: SxProps;
};

const SelectLanguageMenu = ({sx} : Props) : React.ReactElement => {
    const {t, i18n} = useTranslation();
    const {languageCode} = useParams<DashboardParams>();
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('i18nextLng') ?? 'en',
    );

    useEffect(() => {
        if (selectedLanguage !== i18n.language) {
            void i18n.changeLanguage(selectedLanguage);
            setSelectedLanguage(selectedLanguage);
        }
    }, [i18n, selectedLanguage]);

    useEffect(() => {
        if (languageCode) {
            setSelectedLanguage(languageCode);
        }
    }, [languageCode]);

    return <Stack
        sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            ...sx,
        }}
    >
        <Box
            sx={{
                px: 2,
                pb: 1,
                color: '#737373 !important',
            }}
        >
            {t('languageChoice')}
        </Box>
        <Select
            required
            value={selectedLanguage}
            onChange={e => {
                setSelectedLanguage(e.target.value);
            }}
            name='selectedLanguage'
            sx={{
                '& .MuiSelect-outlined': {
                    p: '5px',
                    fontSize: '14px',
                    pl: 1.5,
                    width: '120px',
                },
            }}
        >
            <MenuItem value='en'>English</MenuItem>
            {availableLanguages.map(
                (language: {code: string; name: string}) => {
                    return (
                        <MenuItem
                            key={`language-option-${language.code}`}
                            value={language.code}
                        >
                            {language.name}
                        </MenuItem>
                    );
                },
            )}
        </Select>
    </Stack>
}

export default SelectLanguageMenu;
