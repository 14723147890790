import {isString} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {
    addressWithoutPOBox,
    isState,
    phoneValidation,
    postalCodeUS,
    validateAddress,
    yupDateRegex,
} from '../components/Helpers/validationHelpers';
import {StudentEnrollmentForms} from '../shared/enrollment.keys';
import type {Guardian} from '../shared/model.interface';
import {useCallback} from "react";

const useFormSchema = () => {
    const {t} = useTranslation();

    const getSubFormSchema = useCallback((userStep: number) => {
        if (userStep === 100) {
            return yup.object({});
        }

        if (userStep === 200) {
            return yup
                .object({
                    legalFirstName: yup
                        .string()
                        .required(`${t('sefLegalFirstName')} ${t('requiredError')}`)
                        .nullable(),
                    legalLastName: yup
                        .string()
                        .required(`${t('sefLegalLastName')} ${t('requiredError')}`)
                        .nullable(),
                    legalMiddleName: yup.string().nullable(),
                    preferredName: yup.string().nullable(),
                    dob: yupDateRegex
                        .required(`${t('sef200Dob')} ${t('requiredError')}`)
                        .nullable(),
                    country: yup
                        .string()
                        .required(`${t('sef200Country')} ${t('requiredError')}`)
                        .nullable(),
                    entryDate: yupDateRegex
                        .transform(value => {
                            return value === '' ? null : value;
                        })
                        .when('country', {
                            is: (country: string) => {
                                return country !== '1000';
                            },
                            then: yupDateRegex
                                .transform(value => {
                                    return value === '' ? null : value;
                                })
                                .required(t('requiredDateOther')),
                        }),
                    ethnicity: yup
                        .string()
                        .required(`${t('ethnicity')} ${t('requiredError')}`)
                        .nullable(),
                    race: yup
                        .array()
                        .transform((_value, originalValue) => {
                            if (originalValue === null || originalValue === '') {
                                return [];
                            }

                            return originalValue && isString(originalValue)
                                ? originalValue.split(',')
                                : originalValue.filter((v: unknown) => v) ?? [];
                        })
                        .when('ethnicity', {
                            is: (ethnicity: string) => ethnicity !== 'Yes',
                            then: yup
                                .array()
                                .min(1, `${t('race')} ${t('requiredError')}`)
                                .of(yup.string())
                                .transform(value => {
                                    return Array.isArray(value) ? value : [];
                                })
                                .required(`${t('race')} ${t('requiredError')}`),
                        })
                        .when('ethnicity', {
                            is: (ethnicity: string) => ethnicity === 'Yes',
                            then: yup
                                .array()
                                .of(yup.string())
                                .transform(() => {
                                    return [];
                                }),
                        }),
                    gender: yup
                        .string()
                        .required(`${t('sef200Gender')} ${t('requiredError')}`)
                        .nullable(),
                    registerForGrade: yup
                        .number()
                        .transform(value => (Number.isNaN(value) ? null : value))
                        .required(`${t('sef200Grade')} ${t('requiredError')}`)
                        .nullable(),
                    address: addressWithoutPOBox
                        .required(`${t('sef200Address')} ${t('requiredError')}`)
                        .nullable(),
                    city: yup
                        .string()
                        .required(`${t('sef200City')} ${t('requiredError')}`)
                        .nullable(),
                    state: isState.required(`${t('sef200State')} ${t('requiredError')}`).nullable(),
                    zip: postalCodeUS(`${t('sef200Zip')} ${t('requiredError')}`)
                        .required(`${t('sef200Zip')} ${t('requiredError')}`)
                        .nullable(),
                    mobilePhone: phoneValidation(t('phoneValidation'))
                        .transform(value => {
                            return value !== '' ? value : null;
                        })
                        .nullable(),
                })
                .test(
                    'StudentUSPSValidation',
                    'USPS validation failed',
                    async ({address, city, state, zip}, testContext) => {
                        if (import.meta.env.VITE_REACT_APP_USPS_VALIDATION === 'enabled') {
                            let validatedAddress = {
                                isValid: false,
                                errorType: t('missingFields'),
                            };

                            if (address && city && state && zip) {
                                validatedAddress = await validateAddress(address, city, state, zip);
                            }

                            return !validatedAddress.isValid
                                ? testContext.createError({
                                      message: t('USPSFail') + validatedAddress.errorType,
                                      path: 'address',
                                  })
                                : true;
                        }

                        return true;
                    },
                );
        }

        if (userStep === 201) {
            return yup.object({
                specialEducationServices: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                section504: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                englishLearner: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('requiredErrorFull')}`),
            });
        }

        if (userStep === 202) {
            return yup.object({
                schoolRegisteringFor: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                studentStatus: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                recentSchoolName: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('sef202Name')} ${t('requiredError')}`),
                recentSchoolNameOther: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .when('recentSchoolName', {
                        is: (recentSchoolName: string) => recentSchoolName === 'Other',
                        then: yup
                            .string()
                            .required(`${t('sef202NameOther')} ${t('requiredError')}`),
                    })
                    .nullable(),
                recentSchoolAddress: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .when('recentSchoolName', {
                        is: (recentSchoolName: string) => recentSchoolName === 'Other',
                        then: yup.string().required(`${t('sef202Address')} ${t('requiredError')}`),
                    })
                    .nullable(),
                recentSchoolCity: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .when('recentSchoolName', {
                        is: (recentSchoolName: string) => recentSchoolName === 'Other',
                        then: yup.string().required(`${t('sef202City')} ${t('requiredError')}`),
                    })
                    .nullable(),
                recentSchoolState: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .nullable(),
                recentSchoolCountry: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .when('recentSchoolName', {
                        is: (recentSchoolName: string) => recentSchoolName === 'Other',
                        then: yup.string().required(`${t('sef202Country')} ${t('requiredError')}`),
                    })
                    .nullable(),
            });
        }

        if (userStep === 203) {
            return yup.object({
                guardians: yup
                    .array()
                    .of(
                        yup
                            .object()
                            .shape({
                                firstName: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .required(`${t('sef203GuardianFirst')} is required.`),
                                lastName: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .required(`${t('sef203GuardianLast')} is required.`),
                                relationship: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .required(`${t('sef203GuardianRelationship')} is required.`),
                                relationshipOther: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .when('relationship', {
                                        is: (relationship: string) => relationship === 'Other',
                                        then: yup
                                            .string()
                                            .required(
                                                'You must enter Relationship Other when Relationship is Other.',
                                            ),
                                    }),
                                emailLanguage: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .required(`${t('requiredErrorFull')}`),
                                highestEducation: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .required(`${t('sef203HighestEducation')} is required.`),
                                address: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .when('contactDetails', {
                                        is: (contactDetails: string[]) => {
                                            return !(
                                                contactDetails !== undefined &&
                                                contactDetails.length === 1 &&
                                                contactDetails.includes(
                                                    t('sef203GuardianContactOp5'),
                                                )
                                            );
                                        },
                                        then: addressWithoutPOBox.required(
                                            `${t('sef203GuardianAddress')} is required.`,
                                        ),
                                    }),
                                city: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .when('contactDetails', {
                                        is: (contactDetails: string[]) => {
                                            return !(
                                                contactDetails !== undefined &&
                                                contactDetails.length === 1 &&
                                                contactDetails.includes(
                                                    t('sef203GuardianContactOp5'),
                                                )
                                            );
                                        },
                                        then: yup
                                            .string()
                                            .required(`${t('sef203GuardianCity')} is required.`),
                                    }),
                                state: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .when('contactDetails', {
                                        is: (contactDetails: string[]) => {
                                            return !(
                                                contactDetails !== undefined &&
                                                contactDetails.length === 1 &&
                                                contactDetails.includes(
                                                    t('sef203GuardianContactOp5'),
                                                )
                                            );
                                        },
                                        then: isState.required(
                                            `${t('sef203GuardianState')} is required.`,
                                        ),
                                    }),
                                zip: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .when('contactDetails', {
                                        is: (contactDetails: string[]) => {
                                            return !(
                                                contactDetails !== undefined &&
                                                contactDetails.length === 1 &&
                                                contactDetails.includes(
                                                    t('sef203GuardianContactOp5'),
                                                )
                                            );
                                        },
                                        then: postalCodeUS(
                                            `${t('sef203GuardianZip')} is required.`,
                                        ).required(`${t('sef203GuardianZip')} is required.`),
                                    }),
                                phone1: phoneValidation(t('phoneValidation')).required(
                                    `${t('sef203GuardianPhone1')} is required.`,
                                ),
                                phone1Type: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .required(`${t('sef203GuardianPhoneType')} is required.`),
                                phone2: phoneValidation(t('phoneValidation')),
                                phone2Type: yup.string().transform(value => {
                                    return value === null ? '' : value;
                                }),
                                email: yup
                                    .string()
                                    .transform(value => {
                                        return value === null ? '' : value;
                                    })
                                    .email(`${t('sef203GuardianEmail')} must be valid.`),
                                contactDetails: yup
                                    .array()
                                    .of(yup.string())
                                    .min(1, `${t('sef203GuardianContact')} is required.`)
                                    .transform((_value, originalValue) => {
                                        if (originalValue === null || originalValue === '') {
                                            return [];
                                        }

                                        return originalValue && isString(originalValue)
                                            ? originalValue.split(',')
                                            : originalValue.filter((v: unknown) => v) ?? [];
                                    })
                                    .required(`${t('sef203GuardianContact')} is required.`),
                            })
                            .test(
                                'GuardianUSPSValidation',
                                'USPS validation failed',
                                async ({address, city, state, zip}, testContext) => {
                                    if (
                                        import.meta.env.VITE_REACT_APP_USPS_VALIDATION === 'enabled'
                                    ) {
                                        let validatedAddress = {
                                            isValid: false,
                                            errorType: t('missingFields'),
                                        };

                                        if (address && city && state && zip) {
                                            validatedAddress = await validateAddress(
                                                address,
                                                city,
                                                state,
                                                zip,
                                            );
                                        }

                                        return !validatedAddress.isValid
                                            ? testContext.createError({
                                                  message:
                                                      t('USPSFail') + validatedAddress.errorType,
                                                  path: `${testContext.path}.address`,
                                              })
                                            : true;
                                    }

                                    return true;
                                },
                            )
                            .test(
                                'PhoneNumberDifferenceTest',
                                'Phone Numbers cannot match',
                                ({phone1, phone2}, testContext) => {
                                    const listOfGuardiansMinusCurrent = [...testContext.parent];
                                    const indexFromCurrent = Number.parseInt(
                                        testContext.path.charAt(10),
                                    );
                                    listOfGuardiansMinusCurrent.splice(indexFromCurrent, 1);

                                    if (phone1) {
                                        const matchesFoundForPhone1 = listOfGuardiansMinusCurrent
                                            .map((parent: Guardian) => {
                                                return (
                                                    phone1 === parent.phone1 ||
                                                    phone1 === parent.phone2
                                                );
                                            })
                                            .filter((matchesFound: boolean) => matchesFound);

                                        if (matchesFoundForPhone1.length > 0) {
                                            return testContext.createError({
                                                message: t('phoneCannotMatch'),
                                                path: `${testContext.path}.phone1`,
                                            });
                                        }
                                    }

                                    if (phone2) {
                                        const matchesFoundForPhone2 = listOfGuardiansMinusCurrent
                                            .map((parent: Guardian) => {
                                                return (
                                                    phone2 === parent.phone1 ||
                                                    phone2 === parent.phone2
                                                );
                                            })
                                            .filter((matchesFound: boolean) => matchesFound);

                                        if (matchesFoundForPhone2.length > 0) {
                                            return testContext.createError({
                                                message: t('phoneCannotMatch'),
                                                path: `${testContext.path}.phone2`,
                                            });
                                        }
                                    }

                                    return true;
                                },
                            ),
                    )
                    .min(2, t('requiredContacts')),
            });
        }

        if (userStep === 204) {
            return yup.object({
                hasActiveMilitaryGuardian: yup
                    .boolean()
                    .nullable()
                    .transform(value => {
                        if (value === null || value === '') {
                            return null;
                        }

                        return value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                attendingSiblings: yup.array().of(
                    yup.object().shape({
                        id: yup.string().required(),
                        firstName: yup
                            .string()
                            .required(`${t('sef204SiblingFirstName')} is required.`),
                        lastName: yup
                            .string()
                            .required(`${t('sef204SiblingLastName')} is required.`),
                        idNumber: yup.string().max(6, t('sixMax')),
                        current: yup
                            .boolean()
                            .nullable()
                            .transform(value => {
                                if (value === null || value === '') {
                                    return null;
                                }

                                return value;
                            })
                            .required(`${t('sef204SiblingCurrent')} is required.`),
                    }),
                ),
            });
        }

        if (userStep === 205) {
            return yup.object({
                divorcedOrSeparated: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                documents205: yup.array().of(
                    yup.object().shape({
                        id: yup.string().nullable(),
                        step: yup.string(),
                        name: yup.string().nullable(),
                        willDropOffOrMailIn: yup.boolean(),
                    }),
                ),
            });
        }

        if (userStep === 206) {
            return yup.object({
                medicaidReleaseConsent: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
            });
        }

        if (userStep === 270) {
            return yup.object({
                allergies: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
                asthma: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
                diabetes: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
                gTubeFeeding: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
                seizures: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
            });
        }

        if (userStep === 280) {
            return yup.object({
                studentDirectoryParticipation: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
            });
        }

        if (userStep === 300) {
            return yup.object({
                otherHomeLang: yup
                    .boolean()
                    .nullable()
                    .required(`${t('requiredErrorFull')}`),
                otherHomeLangName: yup
                    .string()
                    .when('otherHomeLang', {
                        is: (otherHomeLang: boolean) => otherHomeLang,
                        then: yup.string().required(`${t('sef300OtherLang')} is required.`),
                    })
                    .nullable(),
                otherStudentLang: yup
                    .boolean()
                    .nullable()
                    .transform(value => {
                        if (value === null || value === '') {
                            return null;
                        }

                        return value;
                    })
                    .required(`${t('requiredErrorFull')}`)
                    .nullable(),
                otherStudentLangName: yup
                    .string()
                    .when('otherStudentLang', {
                        is: (otherStudentLang: boolean) => otherStudentLang,
                        then: yup.string().required(`${t('sef300OtherLang')} is required.`),
                    })
                    .nullable(),
            });
        }

        if (userStep === 400) {
            return yup.object({
                birthCertificateAvailability: yup
                    .boolean()
                    .nullable()
                    .transform(value => {
                        if (value === null || value === '') {
                            return null;
                        }

                        return value;
                    })
                    .required(`${t('requiredErrorFull')}`),
                birthCertificateAvailabilityWhy: yup
                    .string()
                    .nullable()
                    .transform(value => {
                        if (value === null) {
                            return '';
                        }

                        return value;
                    })
                    .when('birthCertificateAvailability', {
                        is: (birthCertificateAvailability: boolean) =>
                            !birthCertificateAvailability,
                        then: yup.string().required(`${t('sefBirthCert')} is required.`),
                    }),
                documents400: yup.array().of(
                    yup.object().shape({
                        id: yup.string().nullable(),
                        name: yup.string().nullable(),
                        step: yup.string(),
                        willDropOffOrMailIn: yup.boolean(),
                    }),
                ),
            });
        }

        if (userStep === 500) {
            return yup
                .object({
                    livingSituation: yup
                        .string()
                        .transform(value => {
                            return value === null ? '' : value;
                        })
                        .required(`${t('requiredErrorFull')}`),
                    leaseExpires: yupDateRegex
                        .transform(value => {
                            return value === null ? '' : value;
                        })
                        .transform(value => {
                            return value === '' ? null : value;
                        }),
                    address: addressWithoutPOBox
                        .transform(value => {
                            return value === null ? '' : value;
                        })
                        .required(`${t('sef500Address')} ${t('requiredError')}`)
                        .nullable(),
                    city: yup
                        .string()
                        .transform(value => {
                            return value === null ? '' : value;
                        })
                        .required(`${t('sef500City')} ${t('requiredError')}`)
                        .nullable(),
                    state: isState
                        .transform(value => {
                            return value === null ? '' : value;
                        })
                        .required(`${t('sef500State')} ${t('requiredError')}`)
                        .nullable(),
                    zip: postalCodeUS(`${t('sef203GuardianZip')} is required.`)
                        .transform(value => {
                            return value === null ? '' : value;
                        })
                        .required(`${t('sef203GuardianZip')} is required.`)
                        .nullable(),
                })
                .test(
                    'USPSValidation',
                    'USPS validation failed',
                    async ({address, city, state, zip}, testContext) => {
                        if (import.meta.env.VITE_REACT_APP_USPS_VALIDATION === 'enabled') {
                            let validatedAddress = {
                                isValid: false,
                                errorType: t('missingFields'),
                            };

                            if (address && city && state && zip) {
                                validatedAddress = await validateAddress(address, city, state, zip);
                            }

                            return !validatedAddress.isValid
                                ? testContext.createError({
                                      message: t('USPSFail') + validatedAddress.errorType,
                                      path: 'address',
                                  })
                                : true;
                        }

                        return true;
                    },
                );
        }

        if (userStep === 501) {
            return yup.object({
                residencySecondary: yup
                    .string()
                    .transform(value => {
                        return value === null ? '' : value;
                    })
                    .when('livingSituation', {
                        is: (livingSituation: string) => {
                            return livingSituation === 'Homeowner' || livingSituation === 'Renter';
                        },
                        then: yup
                            .string()
                            .required(`${t('requiredErrorFull')}`)
                            .nullable(),
                    }),
                residencyTertiary: yup
                    .array()
                    .of(yup.string())
                    .transform((_value, originalValue) => {
                        if (originalValue === null || originalValue === '') {
                            return [];
                        }

                        return originalValue && isString(originalValue)
                            ? originalValue.split(',')
                            : originalValue.filter((v: unknown) => v) ?? [];
                    })
                    .when('livingSituation', {
                        is: (livingSituation: string) =>
                            livingSituation === 'Homeowner' || livingSituation === 'Renter',
                        then: yup
                            .array()
                            .of(yup.string())
                            .min(2, t('twoRequired'))
                            .required(`${t('sef501ResidencyVerification')} ${t('requiredError')}`),
                    }),
                noPermanentResidenceDate: yup
                    .string()
                    .nullable()
                    .when('livingSituation', {
                        is: (livingSituation: string) =>
                            livingSituation === t('sef500Sharing') ||
                            livingSituation === t('sef500Other'),
                        then: yup.string().required(`${t('sef501Date')} ${t('requiredError')}`),
                    }),
                noPermanentResidenceLastAddress: yup
                    .string()
                    .nullable()
                    .when('livingSituation', {
                        is: (livingSituation: string) =>
                            livingSituation === t('sef500Other') ||
                            livingSituation === t('sef500Sharing'),
                        then: yup
                            .string()
                            .required(`${t('sef501OtherLastAddress')} ${t('requiredError')}`),
                    }),
                noPermanentResidenceLastSchool: yup
                    .string()
                    .nullable()
                    .when('livingSituation', {
                        is: (livingSituation: string) => {
                            return (
                                livingSituation === t('sef500Other') ||
                                livingSituation === t('sef500Sharing')
                            );
                        },
                        then: yup
                            .string()
                            .required(`${t('sef501OtherLastSchool')} ${t('requiredError')}`),
                    }),
                unableToProvideDocumentsSituations: yup
                    .array()
                    .of(yup.string())
                    .transform((_value, originalValue) => {
                        if (originalValue === null || originalValue === '') {
                            return [];
                        }

                        return originalValue && isString(originalValue)
                            ? originalValue.split(',')
                            : originalValue.filter((v: unknown) => v) ?? [];
                    })
                    .when(['livingSituation'], {
                        is: (livingSituation: string) => {
                            return (
                                livingSituation === t('sef500Other') ||
                                livingSituation === t('sef500Sharing')
                            );
                        },
                        then: yup
                            .array()
                            .min(1, `${t('sef501OtherSituations')} ${t('requiredError')}`)
                            .of(yup.string())
                            .transform(value => {
                                return value === null ? [] : value;
                            })
                            .required(`${t('sef501OtherSituations')} ${t('requiredError')}`),
                    }),
                unableToProvideDocumentsOther: yup
                    .string()
                    .nullable()
                    .when(['livingSituation', 'unableToProvideDocumentsSituations'], {
                        is: (
                            livingSituation: string,
                            unableToProvideDocumentsSituations: string[],
                        ) => {
                            return (
                                (livingSituation === t('sef500Other') ||
                                    livingSituation === t('sef500Sharing')) &&
                                unableToProvideDocumentsSituations !== undefined &&
                                unableToProvideDocumentsSituations.includes('Other')
                            );
                        },
                        then: yup
                            .string()
                            .required(`${t('sef501OtherOther')} ${t('requiredError')}`),
                    }),
                documents501: yup.array().of(
                    yup.object().shape({
                        id: yup.string().nullable(),
                        name: yup.string().nullable(),
                        step: yup.string(),
                        willDropOffOrMailIn: yup.boolean(),
                    }),
                ),
            });
        }

        if (userStep === 600) {
            return yup.object({
                finalAcknowledgement: yup
                    .boolean()
                    .transform(value => {
                        return value === true;
                    })
                    .oneOf([true], t('mustAgree'))
                    .required(),
                digitalSignature: yup
                    .string()
                    .required(`${t('sef600SigAdd')}`)
                    .nullable(),
                printedSignature: yup
                    .string()
                    .required(`${t('sef600PrintedSig')} ${t('requiredError')}`)
                    .nullable(),
                signatureDate: yup
                    .string()
                    .required(`${t('sef600SigDate')} ${t('requiredError')}`)
                    .nullable(),
            });
        }

        if (userStep === 700) {
            return yup.object({
                documents205: yup.array().of(
                    yup
                        .object()
                        .shape({
                            id: yup.string().nullable(),
                            name: yup.string().nullable(),
                            step: yup.string(),
                            willDropOffOrMailIn: yup.boolean(),
                        })
                        .test(
                            'requiredWhen',
                            `${t('sefPleaseUploadOrSelect')} ${t('sefWillDropOff')}.`,
                            (shapeObject: any) => {
                                const name = shapeObject.name;
                                const willDropOffOrMailIn = shapeObject.willDropOffOrMailIn;
                                return !(willDropOffOrMailIn === false && name === null);
                            },
                        ),
                ),
                documents400: yup.array().of(
                    yup
                        .object()
                        .shape({
                            id: yup.string().nullable(),
                            name: yup.string().nullable(),
                            step: yup.string(),
                            willDropOffOrMailIn: yup.boolean(),
                        })
                        .test(
                            'requiredWhen',
                            `${t('sefPleaseUploadOrSelect')} ${t('sefWillDropOff')}.`,
                            (shapeObject: any) => {
                                const name = shapeObject.name;
                                const willDropOffOrMailIn = shapeObject.willDropOffOrMailIn;
                                return !(willDropOffOrMailIn === false && name === null);
                            },
                        ),
                ),
                documents501: yup.array().of(
                    yup
                        .object()
                        .shape({
                            id: yup.string().nullable(),
                            step: yup.string(),
                            name: yup.string().nullable(),
                            willDropOffOrMailIn: yup.boolean(),
                        })
                        .test(
                            'requiredWhen',
                            `${t('sefPleaseUploadOrSelect')} ${t('sefWillDropOff')}.`,
                            (shapeObject: any) => {
                                const name = shapeObject.name;
                                const willDropOffOrMailIn = shapeObject.willDropOffOrMailIn;
                                return !(willDropOffOrMailIn === false && name === null);
                            },
                        ),
                ),
            });
        }
        return undefined;
    },[t]);

    const getFormSchema = useCallback((userStep?: number) => {
        if (userStep !== undefined) {
            return getSubFormSchema(userStep);
        }

        let schema: any = undefined;

        for (const userStep of StudentEnrollmentForms) {
            const pageSchema = getSubFormSchema(userStep);

            if (schema === undefined) {
                schema = pageSchema;
            } else {
                schema = schema.concat(pageSchema);
            }
        }

        return schema;
    }, [getSubFormSchema]);

    return {getFormSchema};
};

export default useFormSchema;
