import {Box, Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import type * as React from 'react';
import {useTranslation} from 'react-i18next';
import './VideoTutorialModal.scss';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    videoID: string | undefined;
    modalTitle: string;
};

const VideoTutorialModal: React.FC<Props> = ({
    open,
    setOpen,
    videoID,
    modalTitle,
}: Props): React.ReactElement => {
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box>{t(modalTitle)}</Box>
                <IconButton onClick={() => {
                    setOpen(false);
                }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    height: '80vh',
                    minHeight: '80vh',
                    maxHeight: '80vh',
                    pb: 0,
                }}
            >
                {videoID ? (
                    <iframe
                        title={t(modalTitle)}
                        id='player'
                        style={{width: '97%', height: '97%'}}
                        src={`https://www.youtube.com/embed/${videoID}`}
                    />
                ) : (
                    <p>Error: cannot find video ID</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                    color='secondary'
                    variant='outlined'
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VideoTutorialModal;
