import moment from 'moment';
import USPS from 'usps-webtools-promise';
import * as yup from 'yup';
import {getStateNameFromStateCode} from './stateAbbreviationConverter';

const usps = new USPS({
    properCase: true,
    staging: false,
    userId: '992SOLIA5265',
});

export const phoneValidation = (failMessage: string) => {
    return yup
        .string()
        .transform(value => {
            return value === '' ? null : value;
        })
        .nullable()
        .matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}/, failMessage);
};

const convertToDate = (date: string) => {
    return new Date(Date.parse(date));
};

export const yupDateRegex = yup
    .string()
    .transform(value => {
        if (value === null || value === '') {
            return null;
        }

        if (moment(value, 'MM/DD/YYYY', true).isValid()) {
            return moment(convertToDate(value)).format('MM/DD/YYYY');
        }

        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            return moment(value).format('MM/DD/YYYY');
        }

        return null;
    })
    .nullable()
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date format. Use MM/DD/YYYY.');

export const postalCodeUS = (failMessage: string) => {
    return yup.string().matches(/^\d{5}([ -]\d{4})?/, failMessage);
};

export const addressWithoutPOBox = yup
    .string()
    .matches(
        /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
        'Address cannot be a P.O. Box',
    );

export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const isState = yup.string().oneOf(states, 'Invalid state.');

export const validateAddress = async (
    address: string,
    city: string,
    state: string,
    zip: string,
) => {
    let isValid = false;
    let updatedAddress: string | undefined = '';
    let updatedCity: string | undefined = '';
    let updatedState: string | undefined = '';
    let updatedZip: string | undefined = '';
    let errorType = 'Error during verification';

    return usps
        .verify({
            Address2: address,
            City: city,
            State: state,
            Zip5: zip,
        })
        .then(returnedAddress => {
            isValid = true;
            updatedAddress = returnedAddress.Address1;
            errorType = 'No Errors';

            if (returnedAddress.Address2 && returnedAddress.Address1) {
                updatedAddress = returnedAddress.Address1.concat(', ', returnedAddress.Address2);
            }

            if ('ReturnText' in returnedAddress) {
                // a 'ReturnText' exists as a key if the multiple address were found for what the user entered
                isValid = false;
                errorType = 'The address was found but lacks information (i.e. apartment number)';
            }

            if (returnedAddress.State) {
                updatedState = getStateNameFromStateCode(returnedAddress.State);
            }

            updatedCity = returnedAddress.City;
            updatedZip = returnedAddress.Zip5;
            return {isValid, errorType, updatedAddress, updatedCity, updatedState, updatedZip};
        })
        .catch(err => {
            if (err.toString().includes('Invalid Zip Code')) {
                errorType = 'Invalid Zip or State';
            }

            if (err.toString().includes('Address Not Found')) {
                errorType = 'Address Not Found';
            }

            if (err.toString().includes('Invalid City')) {
                errorType = 'Invalid City or State or Zip';
            }

            isValid = false;
            updatedAddress = address;
            updatedCity = city;
            updatedState = state;
            updatedZip = zip;

            return {isValid, errorType, updatedAddress, updatedCity, updatedState, updatedZip};
        });
};
